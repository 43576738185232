<template>
	<div>
        <div class="title-bar">
            <div class="icons">
                <router-link :to="{ name: 'VGReportDetails', params: { project_id: pid, report_id: rid }}" v-if="!isUnsyncedReport()">
                    <font-awesome-icon 
                        :icon="getBackIcon" 
                        class="ui-icon" 
                        ></font-awesome-icon>
                </router-link>

                <router-link :to="{ name: 'VGReportDetailsNew', params: { project_id: pid, report_id: rid }}" v-if="isUnsyncedReport()">
                    <font-awesome-icon 
                        :icon="getBackIcon" 
                        class="ui-icon" 
                        ></font-awesome-icon>
                </router-link>
            </div>
            <div class="title">
                <h1><font-awesome-icon :icon="getVGIcon"></font-awesome-icon> {{ getSerialNumber }}</h1>
            </div>
        </div>

        <Inspection :report="getReport" v-if="!isUnsyncedReport()" />
        <InspectionUnsynced :report="getReport" v-if="isUnsyncedReport()" />
	</div>
</template>

<script>
import { faFireExtinguisher, faWheelchair, faSitemap, faListAlt, faPlusCircle, faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { mapGetters } from 'vuex';
import Inspection from '@/components/report/vg/inspection'
import InspectionUnsynced from '@/components/report/vg/unsynced/inspection'

export default {
    name: "ReportInspection",
    components: {
        Inspection,
        InspectionUnsynced
    },
    props: {
        module: {
            optional: false
        }
    },
    data() {
        return {
            pid: this.$route.params.project_id,
            rid: this.$route.params.report_id
        }
    },
    methods: {
        isUnsyncedReport() {
            return (this.$route.name === "VGReportNewInspection")
        },
    },
    computed: {
		...mapGetters('vg', [
            'getVGReports',
            'getVGProjects',
            'getVGUnsyncedReports'
		]),
        getProject() {
            const pid = this.$route.params.project_id;
            const project = this.getVGProjects.filter((project) => project.id === pid);

            return project[0];
        },
        getProjectTitle() {
            const project = this.getProject()
            return project.title
        },
        getSerialNumber() {
            if (!this.isUnsyncedReport()) {
                const report = this.getReport;

                return `${report.prefix}${report.serial_number}`;
            } else {
                return 'Te syncen rapport';
            }
        },
        getReport() {
            const pid = this.$route.params.project_id;
            const rid = this.$route.params.report_id;

            if (this.isUnsyncedReport()) {
                const report = this.getVGUnsyncedReports[pid].filter((report) => report.id === rid)[0];

                return report;
            }

            const report = this.getVGReports[pid].filter((report) => report.id === rid)[0];

            return report;
        },
        getVGIcon() {
            return faFireExtinguisher
        },
        getAccidentIcon() {
            return faWheelchair
        },
        getSEIcon() {
            return faSitemap
        },
        getPunchListIcon() {
            return faListAlt
        },
        getAddIcon() {
            return faPlusCircle
        },
        getBackIcon() {
            return faChevronCircleLeft
        }
    }
}
</script>