import localforage from "localforage";
import { extendPrototype } from 'localforage-startswith';
import { store } from '@/store';

extendPrototype(localforage);

export const localForageService = localforage.createInstance({
  name: "sms-app",
  version: 1.0,
  storeName: "sms-data"
});

localForageService
    .setDriver([
        localforage.INDEXEDDB,
        localforage.WEBSQL,
        localforage.LOCALSTORAGE
    ])
    .then()
    .catch(error => {
        store.dispatch('notifications/error', 'Probleem met storage: ' + error)
        // console.log("Error setting up storage cache: " + error);
    });

export const unSyncedService = localforage.createInstance({
    name: "sms-app-unsynced",
    version: 1.0,
    storeName: "sms-unsynced"
});
  
unSyncedService
    .setDriver([
        localforage.INDEXEDDB,
        localforage.WEBSQL,
        localforage.LOCALSTORAGE
    ])
    .then()
    .catch(error => {
        store.dispatch('notifications/error', 'Probleem met storage: ' + error)
        // console.log("Error setting up storage cache: " + error);
    });

export default {
  localforage,
  localForageService,
  unSyncedService
};
