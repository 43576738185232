<template>
    <div>
        <div class="row">
			<div class="col-12 col-m-12 col-sm-12">
				<div class="card">
					<div class="card-header bg-primary">
						<h3>
							Basis informatie rapport
						</h3>
					</div>
					<div class="card-content">
                        <div class="row">
                            <div class="row col-md-4 col-4 col-sm-12 col-xs-12">
                                <div class="col-12 col-md-12 col-sm-12 col-xs-12">
                                    <strong>V&amp;G Inspectie #:</strong>
                                    <div class="nl2br">{{ getSerialNumber() }}</div>
                                </div>
                                <div class="col-12 col-md-12 col-sm-12 col-xs-12">
                                    <strong>Locatie:</strong>
                                    <div class="nl2br">{{ report.location }}</div>
                                </div>
                                <div class="col-12 col-md-12 col-sm-12 col-xs-12">
                                    <strong>Betrokken bedrijf:</strong>
                                    <div class="nl2br">{{ getCompanyName(report.company_id) }}</div>
                                </div>
                                <div class="col-12 col-md-12 col-sm-12 col-xs-12">
                                    <strong>Datum Inspectie:</strong>
                                    <div class="nl2br">{{ report.vg_date }}</div>
                                </div>
                                <div class="col-12 col-md-12 col-sm-12 col-xs-12">
                                    <strong>Rapport Datum:</strong>
                                    <div class="nl2br">{{ report.report_date }}</div>
                                </div>
                                <div class="col-12 col-md-12 col-sm-12 col-xs-12">
                                    <strong>Status:</strong>
                                    <div class="nl2br">{{ convertReportStatus(report.status) }}</div>
                                </div>
                            </div>

                            <div class="row col-md-8 col-8 col-sm-12 col-xs-12">
                                <div class="col-12 col-md-12 col-sm-12 col-xs-12">
                                    <strong>(Hoofd)uitvoerder:</strong>
                                    <div class="nl2br">{{ report.chief_engineer }}</div>
                                </div>
                                <div class="col-12 col-md-12 col-sm-12 col-xs-12">
                                    <strong>Samenvatting:</strong>
                                    <div class="nl2br">{{ report.summary }}</div>
                                </div>
                                <div class="col-12 col-md-12 col-sm-12 col-xs-12">
                                    <strong>Aandachtspunten:</strong>
                                    <div class="nl2br">{{ report.attention }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="button-bar col-12">
                    <button class="col-md-3 col-3 col-sm-12 col-xs-12 btn btn-primary">
                        <router-link :to="{ name: 'VGReportInspection', params: { project_id: getPid, report_id: report.id }}">
                            <font-awesome-icon :icon="getInspectionIcon"></font-awesome-icon> Inspectie Items
                        </router-link>
                    </button>
                    <button class="col-md-3 col-3 col-sm-12 col-xs-12 btn btn-primary">
                        <router-link :to="{ name: 'VGReportPhoto', params: { project_id: getPid, report_id: report.id }}">
                            <font-awesome-icon :icon="getImageIcon"></font-awesome-icon> Foto's
                        </router-link>
                    </button>
                    <button class="col-md-3 col-3 col-sm-12 col-xs-12 btn btn-primary">
                        <router-link :to="{ name: 'VGReportSigning', params: { project_id: getPid, report_id: report.id }}">
                            <font-awesome-icon :icon="getSignatureIcon"></font-awesome-icon> Ondertekening
                        </router-link>
                    </button>
                </div>
			</div>
		</div>
    </div>
</template>

<script>
import { faImages, faSignature, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { mapGetters } from 'vuex';

export default {
    name: "General",
    props: {
        report: {
            optional: false
        }
    },
    mounted() {
        const photos = this.report.photos;
        const pid = this.$route.params.project_id;
        const rid = this.$route.params.report_id;
        
        for (let p = 0; p < photos.length; p++) {
            this.$store.dispatch('vg/getReportPhoto', {data: {
                pid: pid, 
                rid: rid, 
                photoId: photos[p].id 
            }});
        }
    },
    methods: {
		...mapGetters('vg', [
            'getVGProjects'
        ]),
        getCompanyName(id) {
            const project = this.getProject();
            return project.companies.filter((company) => company.id === id)[0].name;
        },
        getSerialNumber() {
            const project = this.getProject();

            return `${project.settings.prefix}${this.report.serial_number}`
        },
        getProject() {
            const pid = this.$route.params.project_id;
            const project = this.getVGProjects().filter((project) => project.id === pid);

            return project[0];
        },
        convertReportStatus(status) {
            switch (status) {
                case 0: return "Concept";
                case 1: return "Gepubliceerd";
                case 2: return "Vervallen";
                default:
                    return "Onbekend";
            }
        }
    },
    computed: {
        getPid() {
            return this.$route.params.project_id;
        },
        getInspectionIcon() {
            return faPencilAlt
        },
        getImageIcon() {
            return faImages
        },
        getSignatureIcon() {
            return faSignature
        }
    }
}
</script>