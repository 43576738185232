<template>
    <div class="notification-list">
        <notification-message 
            v-for="notification in notifications" 
            :key="notification.id"
            :notification="notification" />
    </div>
</template>

<script>
import NotificationMessage from './NotificationMessage'
import { mapState } from 'vuex'

export default {
    components: {
        NotificationMessage
    },
    computed: mapState({
        notifications: state => state.notifications.notifications
    })
}
</script>

<style>
    .notification-list {
        position: fixed;
        bottom: 0;
        right: 0;
        margin-right: 10px;
        width: 320px;
        transition: 1s ease-in-out;
    }

    .notification-list .notification-message {
        transition: 1s ease-in-out;
    }
</style>