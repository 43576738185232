import { tokenStorage } from '@/helpers/token-storage';
import { handleResponse } from '@/helpers/response';
import { apiEndpoint, clientId, clientSecret } from '@/config/config'
import axios from 'axios'

export const authentication = {
    namespaced: true,
    state: () => ({
        loggedIn: false,
        requesting: false
    }),
    actions: {
        login ({ dispatch, commit }, { username, password }) {
            commit('REQUEST', { username });

            let grant_type = "password";
            const config = {
                method: 'POST',
                url: `${apiEndpoint}/oauth/v2/token`,
                data: JSON.stringify({ 
                    'client_id': clientId, 
                    'client_secret': clientSecret, 
                    grant_type, 
                    username, 
                    password
                })
            };
        
            axios(config).then(handleResponse)
            .then(
                response => {
                    commit('SUCCESS', response)
                },
                error => {
                    commit('FAILURE', error)
                    dispatch("notifications/error", "Gebruikersnaam en/of wachtwoord onjuist!", { root: true })
                }
            )
        },
        logout ({ commit }) {
            commit('CLEAR');
            tokenStorage.logOut();
        },
        restore ({commit}) {
            commit('RESTORE', ( tokenStorage.getAccessToken() !== null && 
                                tokenStorage.getRefreshToken() !== null));
        }
    },
    mutations: {
        REQUEST (state) {
            state.loggedIn = false;
            state.requesting = true;
        },
        SUCCESS (state, response) {
            tokenStorage.setToken(response);
            state.loggedIn = true;
            state.requesting = false;
        },
        FAILURE (state) {
            tokenStorage.clearToken();
            state.loggedIn = false;
            state.requesting = false;
        },
        CLEAR (state) {
            state.requesting = false;
            state.loggedIn = false;
        },
        RESTORE (state, response) {
            state.loggedIn = response;
            state.requesting = false;
        }
    },
    getters: {
        isLoggedIn () {
            if (tokenStorage.getAccessToken() !== null && tokenStorage.getRefreshToken() !== null) {
                // We have a token...
                return true;
            }

            return false;
        },
        isLoggingIn: state => {
            return state.requesting;
        }
    }
}
