<template>
    <div>
        <div class="title-bar">
            <div class="icons">
                <router-link :to="{ name: 'HomePage', params: { }}">
                    <font-awesome-icon 
                        :icon="getBackIcon" 
                        class="ui-icon" 
                        ></font-awesome-icon>
                </router-link>
            </div>
            <div class="title">
                <h1><font-awesome-icon :icon="getUploadIcon"></font-awesome-icon> Naar SMS</h1>
            </div>
        </div>

        <div class="row">
			<div class="col-12 col-md-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-header bg-primary">
						<h3>
							Naar SMS te versturen rapporten
						</h3>
					</div>
					<div class="card-content">
                        <button class="btn btn-success" @click="startUpload()" v-if="!syncing && Object.keys(getVGUnsyncedReports).length > 0">Start Upload</button>
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Module</th>
                                    <th>Project</th>
                                    <th>Datum</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="project in getVGUnsyncedReports">
                                    <template v-for="report in project">
                                        <tr v-bind:key="report.id" style="transition: 1s ease-in-out;">
                                            <td>
                                                <font-awesome-icon :icon="getUploadingIcon" class="fa-spin" v-if="syncing && report.id === getCurrentUpload"></font-awesome-icon>
                                                <font-awesome-icon :icon="getTodoIcon" v-if="report.id !== getCurrentUpload"></font-awesome-icon>
                                            </td>
                                            <td>V&amp;G</td>
                                            <td>{{ getProjectById(report.project_id) }}</td>
                                            <td>{{ report.vg_date }}</td>
                                        </tr>
                                    </template>
                                </template>
                                <template v-if="Object.keys(getVGUnsyncedReports).length === 0">
                                    <tr>
                                        <td colspan="4" style="text-align: center;">
                                            <em>Geen V&amp;G rapporten om te versturen naar SMS.</em>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { faChevronCircleLeft, faCloudUploadAlt, faSpinner, faHourglassStart } from '@fortawesome/free-solid-svg-icons'
import { mapGetters } from 'vuex';

export default {
    name: "Upload",
    data() {
        return {
            syncing: false
        }
    },
    computed: {
        ...mapGetters('vg', [
            'getVGProjects',
            'getVGUnsyncedReports',
            'getCurrentUpload'
        ]),
        getBackIcon() {
            return faChevronCircleLeft;
        },
        getUploadIcon() {
            return faCloudUploadAlt;
        },
        getUploadingIcon() {
            return faSpinner;
        },
        getTodoIcon() {
            return faHourglassStart;
        }
    },
    methods: {
        getProjectById(id) {
            const project = this.getVGProjects;
            return project.filter((p) => p.id === id)[0].title;
        },
        startUpload() {
            this.syncing = true

            const projects = Object.keys(this.getVGUnsyncedReports);
            projects.forEach((project) => {
                this.getVGUnsyncedReports[project].forEach((report) => {
                    this.$store.dispatch('vg/uploadReport', { item: report })
                })
            });
        }
    },
}
</script>