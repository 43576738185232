<template>
    <div>
        <div class="row">
            <template v-if="report.signings.length === 0">
                <div class="col-xl-12 col-12 col-md-12 col-xs-12 col-sm-12">
                    <p style="text-align: center;"><em>Geen ondertekening bij dit rapport.</em></p>
                </div>
            </template>
            <template v-if="report.signings.length > 0">
                <template v-for="sign in report.signings">
                    <div class="col-xl-4 col-4 col-md-6 col-xs-12 col-sm-12" v-bind:key="sign.id">
                        <div class="card">
                            <div class="card-header bg-primary">
                                <h3>{{ convertSigning(sign.sign_id) }}</h3>
                            </div>
                            <div class="card-content">
                                {{ convertRecipient(sign.recipient_id) }}
                            </div>
                        </div>
                    </div>
                </template>
            </template>
        </div>

        <div class="row">
            <div class="col-12 col-m-12 col-sm-12">
                <template v-if="report.recipients.length === 0">
                    <center><em>Geen verspreiding bij dit rapport.</em></center>
                </template>

                <div class="card" v-if="report.recipients.length > 0">
                    <div class="card-header bg-primary">
                        <h3>Rapport versturen naar</h3>
                    </div>

                    <div class="card-content">
                        <template v-for="(recipient, index) in to">
                            <span v-bind:key="recipient.id">
                                {{ convertRecipient(recipient.recipient_id, (to.length - 1 > index)) }}
                            </span>
                        </template>
                    </div>
                </div>

                <div class="card" v-if="report.recipients.length > 0">
                    <div class="card-header bg-primary">
                        <h3>Kopie rapport versturen naar</h3>
                    </div>

                    <div class="card-content">
                        <template v-for="(recipient, index) in cc">
                            <span v-bind:key="recipient.id">
                                {{ convertRecipient(recipient.recipient_id, (cc.length - 1 > index)) }}
                            </span>
                        </template>
                    </div>
                </div>

                <div class="card" v-if="getDistributionDate !== '-'">
                    <div class="card-header bg-primary">
                        <h3>Rapport verstuurd op</h3>
                    </div>

                    <div class="card-content">
                        {{ getDistributionDate }}
                    </div>
                </div>
            </div>
		</div>
    </div>
</template>

<script>
import { faImages, faSignature, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { mapGetters } from 'vuex';

export default {
    name: "Signing",
    data() {
        return {
            to: [],
            cc: []
        }
    },
    mounted() {
        this.to = this.getTo();
        this.cc = this.getCc();
    },
    props: {
        report: {
            optional: false
        }
    },
    computed: {
		...mapGetters('vg', [
            'getVGProjects',
            'getVGPhotos'
        ]),
        getPid() {
            return this.$route.params.project_id;
        },
        getInspectionIcon() {
            return faPencilAlt
        },
        getImageIcon() {
            return faImages
        },
        getSignatureIcon() {
            return faSignature
        },
        getDistributionDate() {
            if (typeof this.report.distribution_date !== 'undefined' && this.report.distribution_date !== null) {
                return this.report.distribution_date
            }

            return '-';
        }
    },
    methods: {
        getSerialNumber() {
            const project = this.getProject();

            return `${project.settings.prefix}${this.report.serial_number}`
        },
        getProject() {
            const pid = this.$route.params.project_id;
            const project = this.getVGProjects.filter((project) => project.id === pid);

            return project[0];
        },
        convertRecipient(id, comma = false) {
            const project = this.getProject();

            if (comma) {
                return `${project.recipients.filter((recipient) => recipient.id === id)[0].name}, `;
            }

            return `${project.recipients.filter((recipient) => recipient.id === id)[0].name}`;
        },
        convertSigning(id) {
            const project = this.getProject();
            return project.signings.filter((signing) => signing.id === id)[0].title;
        },
        getTo() {
            let to = [];
            this.report.recipients.map((recipient) => {
                if (!recipient.cc) {
                    let recp = { id: recipient.id, recipient_id: recipient.recipient_id }
                    to.push(recp);
                }
            })

            return to;
        },
        getCc() {
            let ccRecipients = [];
            this.report.recipients.map((recipient) => {
                if (recipient.cc) {
                    let recp = { id: recipient.id, recipient_id: recipient.recipient_id }
                    ccRecipients.push(recp);
                }
            })

            return ccRecipients;
        }
    }
}
</script>