export const notifications = {
    namespaced: true,
    state: {
        notifications: []
    },
    actions: {
        success({ commit }, message) {
            commit('SUCCESS', message);
        },
        error({ commit }, message) {
            commit('ERROR', message);
        },
        clear({ commit }) {
            commit('CLEAR');
        },
        remove({ commit }, message) {
            commit('REMOVE', message)
        }
    },
    mutations: {
        SUCCESS(state, message) {
            let notification = {
                type: 'success',
                message: message
            }
            state.notifications.push({
                ...notification,
                id: (Math.random().toString(36) + Date.now().toString(36)).substr(2)
            })
        },
        ERROR(state, message) {
            let notification = {
                type: 'error',
                message: message
            }
            state.notifications.push({
                ...notification,
                id: (Math.random().toString(36) + Date.now().toString(36)).substr(2)
            })
        },
        CLEAR(state) {
            state.notifications = []
        },
        REMOVE(state, notificationToRemove) {
            state.notifications = state.notifications.filter(notification => {
                return notification.id != notificationToRemove.id
            })
        }
    }
}
