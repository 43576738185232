<template>
    <div>
        <div class="row row-list" v-if="show">
            <template v-for="project in projects">
                <div v-bind:key="project.id" class="col-3 col-md-6 col-sm-6 col-xs-12">
                    <router-link :to="{ name: 'VGReportList', params: { project_id: project.id }}">
                        <div class="project-block bg-primary">
                            <div v-if="project.settings.logo !== null" class="project-logo-container">
                                <img :src="project.settings.logo" class="project-logo" />
                            </div>
                            <div v-if="project.settings.logo === null" class="project-logo-container">
                                <img src="@/assets/logo-list.png" class="project-logo" />
                            </div>
                            <p>{{ project.title }}</p>
                        </div>
                    </router-link>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProjectBlocks",
    props: {
        show: {
            optional: false
        },
        projects: {
            optional: false
        }
    }
}
</script>