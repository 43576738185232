import { handleResponse } from '@/helpers/response';
import { apiEndpoint } from '@/config/config'
import { localForageService, unSyncedService } from "@/store/localforage";
import axios from 'axios'

const NAMESPACE = "VG";

export const vg = {
    namespaced: true,
    state: () => ({
        projects: [],
        reports: [],
        photos: [],
        downloading: false,
        toDownload: 0,
        finishedDownload: 0,
        unsyncedReports: [],
        currentUpload: 0,
        tmp: null
    }),
    actions: {
        getAll ({ dispatch, commit }) {

            const config = {
                method: 'GET',
                url: `${apiEndpoint}/api/v2/vg/projects`,
            };
        
            localForageService.startsWith(`${NAMESPACE}-PROJECT`).then((projects) => {
                
                let items = []
                Object.keys(projects).map((key) => {
                    items.push(projects[key])
                })

                commit('SUCCESS_PROJECTS', { result: items })
            })

            commit('DOWNLOADING', true);
            commit('TO_DOWNLOAD', 0);

            axios(config).then(handleResponse)
            .then(
                response => {
                    commit('SUCCESS_PROJECTS', response);

                    commit('TO_DOWNLOAD', response.result.length);
                    response.result.map((project) => {
                        localForageService.setItem(`${NAMESPACE}-PROJECT-${project.title}`, project).then(() => {
                            dispatch('getProjectReports', {id: project.id }).then(() => {
                                commit('ADD_FINISHED_DOWNLOAD', 1);
                                dispatch("notifications/success", `V&G project ${project.title} gedownload`, { root: true })
                            })
                        })
                    });
                },
                error => {
                    dispatch("notifications/error", error, { root: true })
                }
            )
        },
        getProjectReports ({ dispatch, commit }, { id }) {
            const config = {
                method: 'GET',
                url: `${apiEndpoint}/api/v2/vg/project/${id}/reports`,
            };
        
            localForageService.startsWith(`${NAMESPACE}-REPORT-${id}`).then((reports) => {
                let items = []
                Object.keys(reports).reverse().map((key) => {
                    items.push(reports[key])
                })

                commit('SUCCESS_REPORTS', { reports: items, id: id})
            })

            return axios(config).then(handleResponse)
            .then(
                response => {
                    commit('SUCCESS_REPORTS', { reports: response.result, id: id})
                    response.result.map((report) => {
                        localForageService.setItem(`${NAMESPACE}-REPORT-${report.project_id}-${report.serial_number}`, report)
                    });
                },
                error => {
                    dispatch("notifications/error", error, { root: true })
                }
            )
        },
        getReportPhoto ({ dispatch, commit }, { data }) {
            const config = {
                method: 'GET',
                url: `${apiEndpoint}/api/v2/vg/project/${data.pid}/report/${data.rid}/photo/${data.photoId}`,
            };
        
            axios(config).then(handleResponse)
            .then(
                response => {
                    commit('SUCCESS_PHOTO', { reports: response.result, data: data})
                },
                error => {
                    dispatch("notifications/error", error, { root: true })
                }
            )
        },
        restoreFromCache ({ commit }) {
            localForageService.startsWith(`${NAMESPACE}-PROJECT`).then((projects) => {
                
                let items = []
                Object.keys(projects).map((key) => {
                    items.push(projects[key])
                })

                commit('SUCCESS_PROJECTS', { result: items })

                items.map((project) => {
                    localForageService.startsWith(`${NAMESPACE}-REPORT-${project.id}`).then((reports) => {
                        let items = []
                        Object.keys(reports).reverse().map((key) => {
                            items.push(reports[key])
                        })
        
                        commit('SUCCESS_REPORTS', { reports: items, id: project.id})
                    })

                    unSyncedService.startsWith(`${NAMESPACE}-REPORT-${project.id}`).then((reports) => {
                        // let items = []
                        Object.keys(reports).reverse().map((key) => {
                            commit('UNSYNCED_REPORT', reports[key])
                            // items.push(reports[key])
                        })
                    })
                });
            })
        },
        storeUnsyncedReport({ commit }, { report }) {
            commit('UNSYNCED_REPORT', report)
            unSyncedService.setItem(`${NAMESPACE}-REPORT-${report.project_id}-${report.id}`, report)
        },
        uploadReport({ commit, dispatch }, { item }) {
            const pid = item.project_id;

            commit('CURRENT_UPLOAD', item.id)

            const config = {
                method: 'POST',
                url: `${apiEndpoint}/api/v2/vg/project/${pid}/report`,
                data: JSON.stringify(item)
            };
        
            axios(config).then(handleResponse)
            .then(
                response => {
                    commit('UPLOAD_OK', { report: response.result})
                    unSyncedService.removeItem(`${NAMESPACE}-REPORT-${pid}-${item.id}`);
                },
                error => {
                    dispatch("notifications/error", error, { root: true })
                }
            )

            commit('UPLOAD_OK', { item: item, pid: pid })
        }
    },
    mutations: {
        SUCCESS_PROJECTS (state, response) {
            state.projects = response.result;
        },
        SUCCESS_REPORTS (state, response) {
            let projectReports = { [response.id]:  response.reports}
            state.reports = {...state.reports, ...projectReports}
        },
        SUCCESS_PHOTO (state, response) {
            if (typeof state.photos[response.data.rid] === 'undefined') {
                state.photos[response.data.rid] = [];
            }
            let key = state.photos[response.data.rid].length;
            state.photos[response.data.rid][key] = {id: response.data.photoId, photo: response.reports.photo }
        },
        DOWNLOADING (state, status) {
            state.downloading = status
        },
        TO_DOWNLOAD (state, number) {
            state.toDownload = number
            state.finishedDownload = 0
            state.downloading = true
        },
        ADD_FINISHED_DOWNLOAD(state, number) {
            state.finishedDownload += number
            if (state.toDownload > 0 && state.finishedDownload === state.toDownload) {
                state.downloading = false
            }
        },
        UNSYNCED_REPORT(state, report) {
            if (typeof state.unsyncedReports[report.project_id] === 'undefined') {
                const projectReport = { [report.project_id]: [ report ] }
                state.unsyncedReports = { ...state.unsyncedReports, ...projectReport }
            } else {
                const storedReport = state.unsyncedReports[report.project_id].filter((r) => r.id === report.id)
                if (storedReport === null || storedReport.length === 0) {
                    state.unsyncedReports[report.project_id].push(report)
                } else {
                    const index = state.unsyncedReports[report.project_id].indexOf(storedReport[0])
                    state.unsyncedReports[report.project_id][index] = report
                }
            }
        },
        CURRENT_UPLOAD(state, id) {
            state.currentUpload = id
        },
        UPLOAD_OK(state, result) {
            const pid = result.pid;
            const item = result.item;

            if (typeof state.unsyncedReports[pid] !== 'undefined') {
                const index = state.unsyncedReports[pid].indexOf(item);
                state.unsyncedReports[pid].splice(index, 1);

                if (state.unsyncedReports[pid].length === 0) {
                    delete state.unsyncedReports[pid];
                }
            }

            state.currentUpload = 0;
        }
    },
    getters: {
        getVGProjects (state) {
            return state.projects;
        },
        getVGReports (state) {
            return state.reports;
        },
        getVGUnsyncedReports (state) {
            return state.unsyncedReports;
        },
        getVGPhotos (state) {
            return state.photos;
        },
        isDownloading (state) {
            return state.downloading;
        },
        getCurrentUpload (state) {
            return state.currentUpload;
        }
    }
}
