<template>
    <div>
        <div class="notification-message" :type="notification.type">
            {{ notification.message }}
            <div class="notification-progress"></div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        this.timeout = setTimeout(() => {
            this.$store.dispatch("notifications/remove", this.notification)
        }, 5000)
    },
    data() {
        return {
            timeout: null
        }
    },
    props: ['notification'],
    beforeDestroy() {
        clearTimeout(this.timeout)
    }
}
</script>

<style scoped>
@keyframes fadeOut {
	0% 	{ opacity: 0; }
	10% { opacity: 1; }
	90% { opacity: 1; transform: translateX(0px);}
	99% { opacity: 0; transform: translateX(100px);}
	100% { opacity: 0; }
}

@keyframes runProgress {
	0%	{ width: 0%; background: rgba(255,255,255,0.3); }
	100% { width: 96%; background: rgba(255,255,255,1); }
}

.notification-message {
	padding: 20px;
	background-color: var(--success-color);
	color: #fff;
	border-radius: 5px;
	margin: 10px;
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
	animation: fadeOut 5s linear forwards;
}

.notification-message[type="success"] {
	background-color: var(--success-color);
	color: #fff;
}

.notification-message[type="error"] {
	background-color: var(--danger-color);
	color: #fff;
}

.notification-progress {
	width: 0;
	height: 4px;
	background: rgba(255,255,255,0.3);
	position: absolute;
	bottom: 5px;
	left: 2%;
	border-radius: 3px;
	box-shadow: 
		inset 0 1px 1px rgba(0,0,0,0.05), 
		0 -1px 0 rgba(255,255,255,0.6);
    animation: runProgress 4s linear forwards 0.5s;
}
</style>