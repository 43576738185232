<template>
    <div>
        <div class="row">
			<div class="col-12 col-md-12 col-sm-12 col-xs-12">
				<div class="card" id="inspectionBox">
					<div class="card-header bg-primary">
						<h3>
							{{ getChapterNumber }}{{ getChapterTitle }}
						</h3>
                        <div class="dropdown">
                            <button class="dropdown-toggle dropdown-icon" data-toggle="chapter-menu">
                                <div class="dropdown-toggle arrow down" data-toggle="chapter-menu"></div>
                            </button>
                            <ul id="chapter-menu" class="dropdown-menu-selection">
                                <li class="selection-item disabled">Maak een keuze uit onderstaande lijst:</li>
                                <template v-for="(chapter, index) in getChapters">
                                    <li  class="dropdown-menu-item" v-bind:key="chapter.id">
                                        <div class="selection-item" @click="selectChapter(index)">{{ index + 1 }}) {{ chapter.title }}</div>
                                    </li>
                                </template>
                            </ul>
                        </div>
					</div>
					<div class="card-content">
                        <p>{{ getItemIndicator }}{{ getItemTitle }}</p>
                        <div class="row button-bar-inspection" style="margin-top: 30px; margin-bottom: 10px;">
                            <button v-bind:class="{'btn-success': getOk, 'btn-default': !getOk}" 
                                    class="btn col-2 col-md-2 col-sm-6 col-xs-6" @click="setOk()">
                                Goed
                            </button>

                            <button v-bind:class="{'btn-warning': getAttention, 'btn-default': !getAttention}" 
                                    class="btn col-2 col-md-2 col-sm-6 col-xs-6" @click="setAttention()">
                                Aandacht
                            </button>

                            <button v-bind:class="{'btn-danger': getNotOk, 'btn-default': !getNotOk}" 
                                    class="btn col-2 col-md-2 col-sm-6 col-xs-6" @click="setNotOk()">
                                Fout
                            </button>

                            <button v-bind:class="{'btn-info': getNA, 'btn-default': !getNA}" 
                                    class="btn col-2 col-md-2 col-sm-6 col-xs-6" @click="setNA()">
                                N.v.t.
                            </button>
                        </div>

                        <div class="row">
                            <label>Opmerkingen:</label>
                            <textarea class="col-12 col-md-12 col-sm-12 col-xs-12" rows="4" style="margin-top: 10px;" v-model="remarks" v-on:blur="setRemarks()">
                            </textarea>
                        </div>
					</div>
				</div>

                <div class="col-12 col-xs-12 col-md-12 col-sm-12">
                    <button class="btn-success col-12 col-xs-12 col-md-12 col-sm-12" @click="showPhoto = !showPhoto">
                        <font-awesome-icon :icon="getCameraIcon"></font-awesome-icon> Foto toevoegen aan rapport
                    </button>

                    <div class="photo-screen" v-if="showPhoto">
                        <strong>Klik op de knop om een foto toe te voegen</strong>
                        <input  type="file"
                                accept="image/*;capture=camera"
                                @change="onFileChange($event)"
                                class="form-control" />

                        <div class="photo-preview">
                            <img :src="photo" title="Foto Preview" @click="removePhoto()" />
                            <p v-if="photo !== null && photo.length  > 0">
                                <em>Klik op de foto om deze te verwijderen.</em>
                            </p>
                        </div>

                        <span>Omschrijving</span>
                        <textarea class="col-12 col-md-12 col-sm-12 col-xs-12" rows="4" style="margin-top: 10px;" v-model="photo_description">
                        </textarea>

                        <span>Icoon</span>
                        <select name="icon" v-model="icon">
                            <option v-for="option in icons" v-bind:key="option.id" :value="option.id">{{ option.text }}</option>
                        </select>

                        <button id="savePhoto" class="col-md-12 col-12 col-sm-12 col-xs-12 btn btn-primary" @click="savePhoto" v-if="photo != null && photo.length > 0">
                            <font-awesome-icon :icon="getSaveIcon"></font-awesome-icon> Foto opslaan
                        </button>
                    </div>
                </div>

                <div class="button-bar-inspection col-12 col-xs-12 col-md-12 col-sm-12" v-bind:class="{ 'pull-flex-right': !hasPrevious}">
                    <button id="previousButton" class="col-md-3 col-3 col-sm-6 col-xs-6 btn btn-primary" v-if="hasPrevious" @click="goPrevious">
                        <font-awesome-icon :icon="getPrevIcon"></font-awesome-icon> Vorige
                    </button>
                    <button id="nextButton" class="col-md-3 col-3 col-sm-6 col-xs-6 btn btn-primary" v-if="hasNext" @click="goNext">
                        Volgende <font-awesome-icon :icon="getNextIcon"></font-awesome-icon>
                    </button>
                    <button class="col-md-3 col-3 col-sm-6 col-xs-6 btn btn-success" v-if="hasFinished" @click="goFinish">
                        <font-awesome-icon :icon="getFinishIcon"></font-awesome-icon> Afronden
                    </button>
                </div>
			</div>
		</div>
    </div>
</template>

<script>
import { faArrowLeft, faArrowRight, faCheck, faChevronCircleDown, faCamera, faSdCard } from '@fortawesome/free-solid-svg-icons'

export default {
    name: "InspectionUnsynced",
    data() {
        return {
            pid: this.$route.params.project_id,
            chapter: 0,
            subItem: 0,
            showPhoto: false,
            photo: null,
            icon: null,
            photo_description: '',
            icons: [
                { id: "good", text: "Goed" },
                { id: "danger", text: "Gevaarlijk" },
                { id: "housekeeping", text: "Housekeeping" },
                { id: "questionmark", text: "Vraagteken" },
                { id: "sad", text: "Verdrietig" }
            ]
        }
    },
    props: {
        report: {
            optional: false
        }
    },
    methods: {
        onFileChange (e) {
            var files = e.target.files || e.dataTransfer.files

            if (!files.length) {
                return
            }

            this.createImage(files[0])
        },
        createImage (file) {
            const reader = new FileReader()

            reader.onload = (e) => {
                this.photo = e.target.result

                this.$forceUpdate()
            }

            reader.readAsDataURL(file)
        },
        removePhoto () {
            this.photo = null

            this.$forceUpdate()
        },
        goPrevious() {
            setTimeout(() => {
                if (this.subItem > 0) {
                    this.subItem -= 1;
                } else {
                    this.chapter -= 1;
                    this.subItem = (this.report.inspection_items[this.chapter].children.length - 1);
                }
            }, 500);

            let card = document.getElementById("inspectionBox");
            card.classList.remove("run-previous-animation");
            card.classList.remove("run-next-animation");
            void card.offsetWidth;

            card.classList.add("run-previous-animation");
        },
        goNext() {
            setTimeout(() => {
                if (this.report.inspection_items[this.chapter].children.length > (this.subItem + 1)) {
                    this.subItem += 1;
                } else {
                    this.chapter += 1;
                    this.subItem = 0;
                }
            }, 500);

            let card = document.getElementById("inspectionBox");
            card.classList.remove("run-next-animation");
            card.classList.remove("run-previous-animation");
            void card.offsetWidth;

            card.classList.add("run-next-animation");
        },
        goFinish() {
            const pid = this.$route.params.project_id;
            const rid = this.$route.params.report_id;
            this.$router.push({ name: 'VGReportDetailsNew', params: { project_id: pid, report_id: rid }})
        },
        selectChapter(id) {
            let card = document.getElementById("inspectionBox");
            card.classList.remove("run-next-animation");
            card.classList.remove("run-previous-animation");
            void card.offsetWidth;

            if (id < this.chapter) {
                card.classList.add("run-previous-animation"); 
            } else {
                card.classList.add("run-next-animation");
            }

            setTimeout(() => {
                this.chapter = id;
                this.subItem = 0;
            }, 500);
        },
        setOk() {
            this.report.inspection_items[this.chapter].children[this.subItem].conclusion = 1;

            this.$store.dispatch("vg/storeUnsyncedReport", { report: this.report });
        },
        setNotOk() {
            this.report.inspection_items[this.chapter].children[this.subItem].conclusion = 2;

            this.$store.dispatch("vg/storeUnsyncedReport", { report: this.report });
        },
        setAttention() {
            this.report.inspection_items[this.chapter].children[this.subItem].conclusion = 4;

            this.$store.dispatch("vg/storeUnsyncedReport", { report: this.report });
        },
        setNA() {
            this.report.inspection_items[this.chapter].children[this.subItem].conclusion = 3;

            this.$store.dispatch("vg/storeUnsyncedReport", { report: this.report });
        },
        setRemarks() {
            this.$store.dispatch("vg/storeUnsyncedReport", { report: this.report });
        },
        savePhoto() {
            let photo = {
                photo: this.photo,
                description: this.photo_description,
                icon: this.icon
            }

            // this.report.photos = null
            if (this.report.photos === null) {
                this.report.photos = [photo]
            } else {
                this.report.photos.push(photo)
            }

            this.$store.dispatch("vg/storeUnsyncedReport", { report: this.report }).then(() => {
                // We're done, so clean up the photo
                this.photo = null;
                this.photo_description = '';
                this.icon = null;
                this.showPhoto = false;

                this.$store.dispatch("notifications/success", "Foto opgeslagen!")
            }).catch(() => {
                this.$store.dispatch("notifications/danger", "Foto kon niet worden opgeslagen!")
            });
        }
    },
    computed: {
        hasPrevious() {
            if (this.chapter === 0){
                return (this.subItem > 0)
            }

            return true;
        },
        hasNext() {
            if ((this.chapter + 1) === this.report.inspection_items.length) {
                return (this.report.inspection_items[this.chapter].children.length > (this.subItem + 1))
            }

            return true;
        },
        hasFinished() {
            if ((this.chapter + 1) === this.report.inspection_items.length) {
                return (this.report.inspection_items[this.chapter].children.length === (this.subItem + 1))
            }

            return false;
        },
        remarks: {
            get: function() {
                return this.report.inspection_items[this.chapter].children[this.subItem].remarks;
            },
            set: function(value) {
                this.report.inspection_items[this.chapter].children[this.subItem].remarks = value;
            }
        },
        getChapterNumber() {
            const chapterNumber = this.chapter + 1;
            return `${chapterNumber}) `
        },
        getChapterTitle() {
            return this.report.inspection_items[this.chapter].item;
        },
        getItemIndicator() {
            let indicator = ''
            let num = this.subItem
            while (num >= 0) {
                indicator = 'abcdefghijklmnopqrstuwvxyz'[num % 26] + indicator
                num = Math.floor(num / 26) - 1
            }

            return `${indicator}. `
        },
        getItemTitle() {
            return this.report.inspection_items[this.chapter].children[this.subItem].item;
        },
        getOk() {
            return this.report.inspection_items[this.chapter].children[this.subItem].conclusion === 1;
        },
        getNotOk() {
            return this.report.inspection_items[this.chapter].children[this.subItem].conclusion === 2;
        },
        getAttention() {
            return this.report.inspection_items[this.chapter].children[this.subItem].conclusion === 4;
        },
        getNA() {
            const conclusion = this.report.inspection_items[this.chapter].children[this.subItem].conclusion;

            return (conclusion === 3 || conclusion === null)
        },
        getChapters() {
            let items = [];
            this.report.inspection_items.map((item) => {
                let chapter = {id: item.id, title: item.item}
                items.push(chapter)
            })
            return items
        },
        getPrevIcon() {
            return faArrowLeft
        },
        getNextIcon() {
            return faArrowRight
        },
        getFinishIcon() {
            return faCheck
        },
        getDropDownIcon() {
            return faChevronCircleDown
        },
        getCameraIcon() {
            return faCamera
        },
        getSaveIcon() {
            return faSdCard
        }
    }
}
</script>