import Vue from 'vue'
import VueRouter from 'vue-router'
import Page from '@/views/Page.vue'
import LoginPage from '@/views/LoginPage.vue'
import { tokenStorage } from '@/helpers/token-storage';

Vue.use(VueRouter)

const routes = [
    {
        path: '/home',
        name: 'HomePage',
        component: Page
    },
    {
        path: '/vg',
        name: 'VGProjectList',
        component: Page
    },
    {
        path: '/vg/:project_id',
        name: 'VGReportList',
        component: Page
    },
    {
        path: '/vg/:project_id/report/new/:report_id/general',
        name: 'VGReportDetailsNew',
        component: Page
    },
    {
        path: '/vg/:project_id/report/new/:report_id/inspection',
        name: 'VGReportNewInspection',
        component: Page
    },
    {
        path: '/vg/:project_id/report/new/:report_id/photos',
        name: 'VGReportNewPhoto',
        component: Page
    },
    {
        path: '/vg/:project_id/report/new/:report_id/signing',
        name: 'VGReportNewSigning',
        component: Page
    },
    {
        path: '/vg/:project_id/report/new',
        name: 'VGReportNew',
        component: Page
    },
    {
        path: '/vg/:project_id/report/:report_id',
        name: 'VGReportDetails',
        component: Page
    },
    {
        path: '/vg/:project_id/report/:report_id/inspection',
        name: 'VGReportInspection',
        component: Page
    },
    {
        path: '/vg/:project_id/report/:report_id/photos',
        name: 'VGReportPhoto',
        component: Page
    },
    {
        path: '/vg/:project_id/report/:report_id/signing',
        name: 'VGReportSigning',
        component: Page
    },
    { 
        path: '/upload',
        name: 'UploadPage',
        component: Page 
    },
    { 
        path: '/download',
        name: 'DownloadPage',
        component: Page 
    },
    { 
        path: '/login',
        name: 'login',
        component: LoginPage 
    },
    {
        path: '*',
        redirect: '/home' 
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login', '/about'];
    const authRequired = !publicPages.includes(to.path);

    if (authRequired && !(await tokenStorage.getAccessToken() !== null && await tokenStorage.getRefreshToken() !== null)) {
        return next('/login');
    }

    next();
})

export default router;
