<template>
    <div>
        <div class="row">
			<div class="col-12 col-md-12 col-sm-12 col-xs-12">
				<div class="card" id="inspectionBox">
					<div class="card-header bg-primary">
						<h3>
							{{ getChapterNumber }}{{ getChapterTitle }}
						</h3>
                        <div class="dropdown">
                            <button class="dropdown-toggle dropdown-icon" data-toggle="chapter-menu">
                                <div class="dropdown-toggle arrow down" data-toggle="chapter-menu"></div>
                            </button>
                            <ul id="chapter-menu" class="dropdown-menu-selection">
                                <li class="selection-item disabled">Maak een keuze uit onderstaande lijst:</li>
                                <template v-for="(chapter, index) in getChapters">
                                    <li  class="dropdown-menu-item" v-bind:key="chapter.id">
                                        <div class="selection-item" @click="selectChapter(index)">{{ index + 1 }}) {{ chapter.title }}</div>
                                    </li>
                                </template>
                            </ul>
                        </div>
					</div>
					<div class="card-content">
                        <p>{{ getItemIndicator }}{{ getItemTitle }}</p>
                        <div class="row button-bar-inspection" style="margin-top: 30px; margin-bottom: 10px;">
                            <button v-bind:class="{'btn-success': getOk, 'btn-default': !getOk}" class="btn col-2 col-md-2 col-sm-6 col-xs-6">Goed</button>
                            <button v-bind:class="{'btn-warning': getAttention, 'btn-default': !getAttention}" class="btn col-2 col-md-2 col-sm-6 col-xs-6">Aandacht</button>
                            <button v-bind:class="{'btn-danger': getNotOk, 'btn-default': !getNotOk}" class="btn col-2 col-md-2 col-sm-6 col-xs-6">Fout</button>
                            <button v-bind:class="{'btn-info': getNA, 'btn-default': !getNA}" class="btn col-2 col-md-2 col-sm-6 col-xs-6">N.v.t.</button>
                        </div>

                        <div class="row">
                            <label>Opmerkingen:</label>
                            <textarea class="col-12 col-md-12 col-sm-12 col-xs-12" rows="4" style="margin-top: 10px;" v-model="getRemarks">
                            </textarea>
                        </div>
					</div>
				</div>

                <div class="button-bar-inspection col-12 col-xs-12 col-md-12 col-sm-12" v-bind:class="{ 'pull-flex-right': !hasPrevious}">
                    <button id="previousButton" class="col-md-3 col-3 col-sm-6 col-xs-6 btn btn-primary" v-if="hasPrevious" @click="goPrevious">
                        <font-awesome-icon :icon="getPrevIcon"></font-awesome-icon> Vorige
                    </button>
                    <button id="nextButton" class="col-md-3 col-3 col-sm-6 col-xs-6 btn btn-primary" v-if="hasNext" @click="goNext">
                        Volgende <font-awesome-icon :icon="getNextIcon"></font-awesome-icon>
                    </button>
                    <button class="col-md-3 col-3 col-sm-6 col-xs-6 btn btn-success" v-if="hasFinished" @click="goFinish">
                        <font-awesome-icon :icon="getFinishIcon"></font-awesome-icon> Afronden
                    </button>
                </div>
			</div>
		</div>
    </div>
</template>

<script>
import { faArrowLeft, faArrowRight, faCheck, faChevronCircleDown } from '@fortawesome/free-solid-svg-icons'

export default {
    name: "Inspection",
    data() {
        return {
            pid: this.$route.params.project_id,
            chapter: 0,
            subItem: 0
        }
    },
    props: {
        report: {
            optional: false
        }
    },
    methods: {
        goPrevious() {
            setTimeout(() => {
                if (this.subItem > 0) {
                    this.subItem -= 1;
                } else {
                    this.chapter -= 1;
                    this.subItem = (this.report.inspection_items[this.chapter].children.length - 1);
                }
            }, 500);

            let card = document.getElementById("inspectionBox");
            card.classList.remove("run-previous-animation");
            card.classList.remove("run-next-animation");
            void card.offsetWidth;

            card.classList.add("run-previous-animation");
        },
        goNext() {
            setTimeout(() => {
                if (this.report.inspection_items[this.chapter].children.length > (this.subItem + 1)) {
                    this.subItem += 1;
                } else {
                    this.chapter += 1;
                    this.subItem = 0;
                }
            }, 500);

            let card = document.getElementById("inspectionBox");
            card.classList.remove("run-next-animation");
            card.classList.remove("run-previous-animation");
            void card.offsetWidth;

            card.classList.add("run-next-animation");
        },
        goFinish() {
            const pid = this.$route.params.project_id;
            const rid = this.$route.params.report_id;
            this.$router.push({ name: 'VGReportDetails', params: { project_id: pid, report_id: rid }})
        },
        selectChapter(id) {
            let card = document.getElementById("inspectionBox");
            card.classList.remove("run-next-animation");
            card.classList.remove("run-previous-animation");
            void card.offsetWidth;

            if (id < this.chapter) {
                card.classList.add("run-previous-animation"); 
            } else {
                card.classList.add("run-next-animation");
            }

            setTimeout(() => {
                this.chapter = id;
                this.subItem = 0;
            }, 500);
        },
    },
    computed: {
        hasPrevious() {
            if (this.chapter === 0){
                return (this.subItem > 0)
            }

            return true;
        },
        hasNext() {
            if ((this.chapter + 1) === this.report.inspection_items.length) {
                return (this.report.inspection_items[this.chapter].children.length > (this.subItem + 1))
            }

            return true;
        },
        hasFinished() {
            if ((this.chapter + 1) === this.report.inspection_items.length) {
                return (this.report.inspection_items[this.chapter].children.length === (this.subItem + 1))
            }

            return false;
        },
        getRemarks() {
            return this.report.inspection_items[this.chapter].children[this.subItem].remarks;
        },
        getChapterNumber() {
            const chapterNumber = this.chapter + 1;
            return `${chapterNumber}) `
        },
        getChapterTitle() {
            return this.report.inspection_items[this.chapter].item;
        },
        getItemIndicator() {
            let indicator = ''
            let num = this.subItem
            while (num >= 0) {
                indicator = 'abcdefghijklmnopqrstuwvxyz'[num % 26] + indicator
                num = Math.floor(num / 26) - 1
            }

            return `${indicator}. `
        },
        getItemTitle() {
            return this.report.inspection_items[this.chapter].children[this.subItem].item;
        },
        getOk() {
            return this.report.inspection_items[this.chapter].children[this.subItem].conclusion === 1;
        },
        getNotOk() {
            return this.report.inspection_items[this.chapter].children[this.subItem].conclusion === 2;
        },
        getAttention() {
            return this.report.inspection_items[this.chapter].children[this.subItem].conclusion === 4;
        },
        getNA() {
            const conclusion = this.report.inspection_items[this.chapter].children[this.subItem].conclusion;

            return (conclusion === 3 || conclusion === null)
        },
        getChapters() {
            let items = [];
            this.report.inspection_items.map((item) => {
                let chapter = {id: item.id, title: item.item}
                items.push(chapter)
            })
            return items
        },
        getPrevIcon() {
            return faArrowLeft
        },
        getNextIcon() {
            return faArrowRight
        },
        getFinishIcon() {
            return faCheck
        },
        getDropDownIcon() {
            return faChevronCircleDown
        }
    }
}
</script>