<template>
    <div>
        <div class="row">
			<div class="col-12 col-m-12 col-sm-12">
				<div class="card">
					<div class="card-header bg-primary">
						<h3>
							Basis informatie rapport
						</h3>
					</div>
					<div class="card-content">
                        <div class="row">
                            <div class="row col-md-4 col-4 col-sm-12 col-xs-12">
                                <div class="col-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="form-group">
                                        <strong>V&amp;G Inspectie #:</strong>
                                        <div class="nl2br">n.t.b.</div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="form-group">
                                        <strong>Locatie:</strong>
                                        <input type="text" v-model="report.location" id="location" required v-on:blur="blurring" />
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="form-group">
                                        <strong>Betrokken bedrijf:</strong>
                                        <select class="form-control" v-model="report.company_id" required>
                                            <option v-for="value in getCompanies" v-bind:value="value.id" v-bind:key="value.id" v-on:blur="blurring">{{ value.name }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="form-group">
                                        <strong>Datum Inspectie:</strong>
                                        <input type="date" v-model="report.vg_date" id="vg_date" required v-on:blur="blurring" />
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="form-group">
                                        <strong>Rapport Datum:</strong>
                                        <input type="date" v-model="report.report_date" id="report_date" v-on:blur="blurring" />
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="form-group">
                                        <strong>Status:</strong>
                                        <select class="form-control" v-model="report.status" required v-on:blur="blurring">
                                            <option v-for="value in status" v-bind:value="value.value" v-bind:key="value.value">{{ value.display }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row col-md-8 col-8 col-sm-12 col-xs-12">
                                <div class="col-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="form-group">
                                        <strong>(Hoofd)uitvoerder:</strong>
                                        <textarea id="chief_engineer" v-model="report.chief_engineer" class="form-control" rows="5" required v-on:blur="blurring"></textarea>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="form-group">
                                        <strong>Samenvatting:</strong>
                                        <textarea id="chief_engineer" v-model="report.summary" rows="5" v-on:blur="blurring"></textarea>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="form-group">
                                        <strong>Aandachtspunten:</strong>
                                        <textarea id="chief_engineer" v-model="report.attention" rows="5" v-on:blur="blurring"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="button-bar col-12">
                    <button class="col-md-3 col-3 col-sm-12 col-xs-12 btn btn-primary">
                        <router-link :to="{ name: 'VGReportNewInspection', params: { project_id: getPid, report_id: report.id }}">
                            <font-awesome-icon :icon="getInspectionIcon"></font-awesome-icon> Inspectie Items
                        </router-link>
                    </button>

                    <button class="col-md-3 col-3 col-sm-12 col-xs-12 btn btn-primary">
                        <router-link :to="{ name: 'VGReportNewPhoto', params: { project_id: getPid, report_id: report.id }}">
                            <font-awesome-icon :icon="getImageIcon"></font-awesome-icon> Foto's
                        </router-link>
                    </button>
                </div>
			</div>
		</div>
    </div>
</template>

<script>
import { faImages, faSignature, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { mapGetters } from 'vuex';

export default {
    name: "GeneralUnsynced",
    props: {
        report: {
            optional: false
        }
    },
    data() {
        return {
            status: [
                { value: 0, display: "Concept" }
            ]
        }
    },
    methods: {
		...mapGetters('vg', [
            'getVGProjects'
        ]),
        getCompanyName(id) {
            const project = this.getProject();
            return project.companies.filter((company) => company.id === id)[0].name;
        },
        getProject() {
            const pid = this.$route.params.project_id;
            const project = this.getVGProjects().filter((project) => project.id === pid);

            return project[0];
        },
        blurring() {
            this.$store.dispatch("vg/storeUnsyncedReport", { report: this.report });
        }
    },
    computed: {
        getPid() {
            return this.$route.params.project_id;
        },
        getInspectionIcon() {
            return faPencilAlt
        },
        getImageIcon() {
            return faImages
        },
        getSignatureIcon() {
            return faSignature
        },
        getCompanies() {
            const project = this.getProject()
            return project.companies;
        }
    }
}
</script>