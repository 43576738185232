import Vue from 'vue'
import Vuex from 'vuex'

import { notifications } from '@/store/modules/notifications.module'
import { authentication } from '@/store/modules/authentication.module'
import { vg } from '@/store/modules/vg.module'
import { user } from '@/store/modules/user.module'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    notifications,
    authentication,
    vg,
    user
  }
})
