<template>
    <div>
		<h1>{{ getTimeOfDay }} {{ getFirstName }}</h1>
		<div class="row">
			<div class="col-3 col-md-6 col-sm-6 col-xs-12" v-if="hasVG">
				<router-link :to="{ name: 'VGProjectList', params: { }}">
					<div class="counter bg-primary">
						<p>
							<font-awesome-icon :icon="getVGIcon" class="module-icon" />
						</p>
						<h3>V&amp;G</h3>
						<p>{{ getVGProjects.length }} Projecten</p>
					</div>
				</router-link>
			</div>
			<div class="col-3 col-md-6 col-sm-6 col-xs-12" v-if="hasAccident">
				<div class="counter bg-warning">
					<p>
						<font-awesome-icon :icon="getAccidentIcon" class="module-icon" />
					</p>
					<h3>(Bijna)Ongevallen</h3>
					<p>Nog niet ondersteund</p>
				</div>
			</div>
			<div class="col-3 col-md-6 col-sm-6 col-xs-12" v-if="hasPunchList">
				<div class="counter bg-success">
					<p>
						<font-awesome-icon :icon="getPunchListIcon" class="module-icon" />
					</p>
					<h3>Restpunten</h3>
					<p>Nog niet ondersteund</p>
				</div>
			</div>
			<div class="col-3 col-md-6 col-sm-6 col-xs-12" v-if="hasSE">
				<div class="counter bg-danger">
					<p>
						<font-awesome-icon :icon="getSEIcon" class="module-icon" />
					</p>
					<h3>Keuringen</h3>
					<p>Nog niet ondersteund</p>
				</div>
			</div>
			<!-- <div class="col-3 col-md-6 col-sm-6 col-xs-12" v-if="hasVisitReport">
				<div class="counter bg-danger">
					<p>
						<font-awesome-icon :icon="getVisitReportIcon" class="module-icon" />
					</p>
					<h3>N/A</h3>
					<p>Nog niet ondersteund</p>
				</div>
			</div> -->
		</div>

		<div class="row">
			<div class="col-6 col-md-6 col-sm-6 col-xs-12">
				<button class="btn btn-success" style="width: 100%;" @click="downloadAll()">
					<font-awesome-icon :icon="getSpinnerIcon" class="fa-spin" v-if="isDownloading" /> 
					<font-awesome-icon :icon="getDownloadIcon" v-if="!isDownloading" /> 
					&nbsp;Alles Downloaden
				</button>
			</div>

			<div class="col-6 col-md-6 col-sm-6 col-xs-12">
				<router-link :to="{ name: 'UploadPage', params: { }}">
					<button class="btn btn-success" style="width: 100%;">
						<font-awesome-icon :icon="getUploadIcon" />
						&nbsp;Alles Uploaden
					</button>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import { faFireExtinguisher, faWheelchair, faSitemap, faListAlt, faCloudDownloadAlt, faCloudUploadAlt, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { mapGetters } from 'vuex';

export default {
	name: "Home",
    computed: {
		...mapGetters('vg', [
			'getVGProjects',
			'doneDownloading',
			'isDownloading'
		]),
		...mapGetters('user', [
			'getFirstName',
			'getFullName',
			'hasVG',
			'hasSE',
			'hasAccident',
			'hasPunchList',
			'hasVisitReport'
		]),
		getTimeOfDay() {
			const now = new Date();
			if (now.getHours() >= 0 && now.getHours() < 6) { return "Goedenacht"; }
			if (now.getHours() >= 6 && now.getHours() < 12) { return "Goedemorgen"; }
			if (now.getHours() >= 12 && now.getHours() < 18) { return "Goedemiddag"; }

			return "Goedenavond";
		},
        getVGIcon() {
            return faFireExtinguisher
        },
        getAccidentIcon() {
            return faWheelchair
        },
        getSEIcon() {
            return faSitemap
        },
        getPunchListIcon() {
            return faListAlt
        },
		getDownloadIcon() {
			return faCloudDownloadAlt
		},
		getUploadIcon() {
			return faCloudUploadAlt
		},
		getSpinnerIcon() {
			return faSpinner
		}
        // getVisitReportIcon() {
        //     return faStackExchange
        // }
    },
	methods: {
		downloadAll() {
			this.$store.dispatch('vg/getAll')
		}
	}
}
</script>