<template>
  <div id="app">
		<template>
			<router-view :key="$route.fullPath" />
		</template>

		<notification-list />
  </div>
</template>

<script>
import NotificationList from './components/notifications/NotificationList'
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import { localForageService } from "@/store/localforage";

// const themeCookieName = 'theme'
// const themeDark = 'dark'
const themeLight = 'light'

const body = document.getElementsByTagName('body')[0]

export default {
	name: 'App',
	components: {
		NotificationList
	},
	computed: {
		...mapGetters(['authentication/isLoggedIn'])
	},
	methods: {
		...mapActions(['authentication/restore']),
		loadTheme() {
			localForageService.getItem('PREFERENCES').then((theme) => {
				// console.log("Theme: ", theme)
				if (theme !== null && typeof theme !== 'undefined') {
					body.classList.add(theme.theme)
				}
				else {
					body.classList.add(themeLight)
				}
			});
		},
		collapseSidebar() {
			body.classList.toggle('sidebar-expand')
		},
		closeAllDropdowns() {
			var dropdowns = document.getElementsByClassName('dropdown-expand')

			for (var i = 0; i < dropdowns.length; i++) {
				dropdowns[i].classList.remove('dropdown-expand')
			}
		},
		openCloseDropdown(event) {
			if (!event.target.matches('.dropdown-toggle')) {
				this.closeAllDropdowns()
			} else {
				var toggle = event.target.dataset.toggle
				var content = document.getElementById(toggle)
				
				if (content.classList.contains('dropdown-expand')) {
					this.closeAllDropdowns()
				} else {
					this.closeAllDropdowns()
					content.classList.add('dropdown-expand')
				}
			}
		}
	},
	mounted() {
		this.restore;
	},
	created () {
		this.loadTheme()
			window.addEventListener("click", (event) => {
			this.openCloseDropdown(event)
		})

		this.$store.dispatch("vg/restoreFromCache")
	}
}
</script>

<style>
:root {
	--primary-color: #4834d4;
	--success-color: #6ab04c;
	--warning-color: #f0932b;
	--danger-color: #eb4d4b;
	
	--light-color: #212529;
	--light-second-color: #727475;
	--light-background: #ffffff;
	--light-second-background: #f1f1f1;
	--light-navbar-color: #f1f1f1;

	--dark-color: #909090;
	--dark-second-color: #f5f6fa;
	--dark-background: #2c2c2c;
	--dark-second-background: #151515;
	--dark-navbar-color: #313131;

	--blue-light: #007bff;
	--blue-dark: #034c9b;
	--indigo: #6610f2;
	--purple: #6f42c1;
	--pink: #e83e8c;
	--red: #dc3545;
	--orange: #fd7e14;
	--yellow-light: #ffc107;
	--yellow-dark: #b18607;
	--green-light: #28a745;
	--green-dark: #1f7a34;
	--teal: #20c997;
	--cyan: #17a2b8;
	--white: #fff;
	--gray: #6c757d;
	--gray-dark: #343a40;
	--primary: #007bff;
	--secondary: #6c757d;
	--success: #28a745;
	--info: #17a2b8;
	--warning: #ffc107;
	--danger-light: #dc3545;
	--danger-dark: #8b2731;
	--info: #f8f9fa;
}

.light {
	--main-bg-color: var(--light-background);
	--second-bg-color: var(--light-second-background);
	--main-color: var(--light-color);
	--second-color: var(--light-second-color);
	--navbar-color: var(--light-navbar-color);
	--icon-active-color: var(--light-color);
	--icon-color: var(--light-second-color);
	
	--blue: var(--blue-light);
	--yellow: var(--yellow-light);
	--green: var(--green-light);
	--danger: var(--danger-light);
}

.dark {
	--main-bg-color: var(--dark-background);
	--second-bg-color: var(--dark-second-background);
	--main-color: var(--dark-color);
	--second-color: var(--dark-second-color);
	--navbar-color: var(--dark-navbar-color);
	--icon-active-color: var(--dark-second-color);
	--icon-color: var(--dark-color);

	--blue: var(--blue-dark);
	--yellow: var(--yellow-dark);
	--green: var(--green-dark);
	--danger: var(--danger-dark);	
}

.light .logo-dark, .light .light-icon {
	display: none;
}

.dark .logo-light, .dark .dark-icon {
	display: none;
}

.bg-primary {
  background: var(--blue);
  color: #fff;
}

.bg-success {
  background: var(--green);
  color: #fff;
}

.bg-warning {
  background: var(--yellow);
  color: #000;
}

.bg-danger {
  background: var(--red);
  color: #fff;
}

* {
	box-sizing: border-box;
}

.button-bar {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.button-bar-inspection {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.photo-report-item {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	vertical-align: top;
}

body {
	font-family: 'Roboto', sans-serif;
	background: var(--main-bg-color);
	color: var(--main-color);
	margin: 0;
}

.navbar {
	display: flex;
	flex-direction: row;
	background: var(--navbar-color);
	color: var(--main-color);
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 200;
	box-shadow: rgba(0,0,0,0.12) 0px 1px 4px 1px;
}

.navbar-nav {
	list-style: none;
	display: flex;
	flex-direction: row;
	padding-left: 0;
	margin-bottom: 0;
	margin-top: 0;
}

.nav-link {
	float: left;
	text-align: center;
	padding: 21px 10px;
	text-decoration: none;
	font-size: 17px;
	color: var(--second-color);
	position: relative;
}

.nav-link:hover {
	cursor: pointer;
}

.nav-icon {
	float: left;
	text-align: center;
	padding: 21px 10px;
	text-decoration: none;
	font-size: 17px;
	color: var(--second-color);
	position: relative;
}

.navbar-nav svg {
	font-size: 1.4rem;
}

.nav-right {
	margin-left: auto;
}

.navbar-search {
	position: relative;
	margin: auto;
	width: 50%;
}

.navbar-search input {
	background: var(--second-bg-color);
	border: none;
	padding: 12px 50px 12px 50px;
	width: 100%;
	outline: none;
	color: var(--main-color);
	border-radius: 20px;
}

.navbar-search svg {
	position: absolute;
	top: 50%;
	right: 20px;
	transform: translateY(-50%);
	color: var(--second-color);
	cursor: pointer;
}

.logo {
	height: 60px;
	padding: 10px;
}

.avatar img {
	width: 39px;
	height: 39px;
	border-radius: 50%;
}

.avatar {
	padding: 11px
}

.navbar-badge {
	font-size: .6rem;
	padding: 2px 4px;
	font-weight: 300;
	position: absolute;
	right: 5px;
	top: 9px;
	color: #fff;
	background: var(--danger-color);
	border-radius: 5px;
}

.dropdown {
	position: relative;
}

.dropdown:hover {
	cursor: pointer;
}

.dropdown-menu {
	list-style: none;
	display: none;
	flex-direction: column;
	position: absolute;
	right: 20%;
	top: 90%;
	background: var(--main-bg-color);
	max-width: 300px;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	z-index: 1;
	padding: unset;
	border-radius: 10px;
	border: 0.125px solid var(--second-bg-color);
}

.dropdown-expand {
	display: flex;
	transition: 1s ease-in-out;
}

.notification-menu {
	min-width: 380px;
}

.dropdown-menu-content {
	max-height: 500px;
	overflow-y: auto;
}

.dropdown-menu-item {
	width: 100%;
}

.dropdown-menu-link {
	display: flex;
	flex-direction: row;
	align-items: center;
	text-decoration: none;
	color: var(--main-color);
}

.dropdown-menu-link div {
	min-width: 82px;
	height: 50px;
	position: relative;
}

.dropdown-menu-link svg {
	font-size: 1.25rem;
	color: var(--second-color);
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.dropdown-menu-link span {
	font-size: 15px;
	width: 298px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.dropdown-menu-link span > span {
	font-size: 10px;
}

.dropdown-menu-link:hover {
	cursor: pointer;
	background: var(--second-bg-color);
}

.dropdown-menu-header {
	padding: 20px;
	text-align: center;
	border-bottom: 1px solid var(--second-bg-color);
}

.dropdown-menu-footer {
	padding: 20px;
	text-align: center;
	border-top: 1px solid var(--second-bg-color);
}

.overlay-scrollbar::-webkit-scrollbar {
	width: 9px;
	height: 9px;
}

.scrollbar-hover::-webkit-scrollbar {
	display: none;
}

.scrollbar-hover:hover::-webkit-scrollbar {
	display: block;
}

.overlay-scrollbar::-webkit-scrollbar-thumb {
	background-color: rgba(0,0,0,0.4);
	border-radius: 10rem;
	border: 1px solid var(--main-bg-color);
}

.overlay-scrollbar::-webkit-scrollbar-track-piece:start {
	background: transparent;
}

.overlay-scrollbar::-webkit-scrollbar-track-piece:end {
	background: transparent;
}

.wrapper {
	padding: 10px;
	margin-left: 82px;
	padding-top: 75px;
	background: var(--second-bg-color);
	transition: margin-left .5s;
	height: 100%;
	min-height: 100vh;
}

.wrapper h1 {
	margin-left: 10px;
}

.sidebar {
	width: 82px;
	height: 100vh;
	position: fixed;
	margin-top: 65px;
	background: var(--main-bg-color);
	box-shadow: rgba(0,0,0,0.12) 0px 1px 4px 1px;
	overflow: hidden;
	z-index: 100;
	transition: width .5s;
}

.sidebar:hover {
	width: 250px;
}

.sidebar-nav {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100vh;
}

.sidebar-nav-item {
	width: 100%;
}

.sidebar-nav-link {
	display: flex;
	align-items: center;
	color: var(--main-color);
	text-decoration: none;
}

.sidebar-nav-link svg {
	font-size: 1.5rem;
	color: var(--second-color);
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.sidebar-nav-link div {
	min-width: 82px;
	height: 50px;
	position: relative;
}

.sidebar-nav-link:hover {
	background: var(--second-bg-color);
}

.sidebar-nav-link.router-link-active {
	background: var(--second-bg-color);
	color: var(--danger-color);
}

.sidebar-nav-link.router-link-active div {
	border-left: 5px solid var(--danger-color);
}

.sidebar-nav-link.router-link-active svg {
	color: var(--danger-color);
	margin-left: -5px;
}

.sidebar-expand .sidebar {
	width: 250px;
}

.sidebar-expand .wrapper {
	margin-left: 250px;
}

.row {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
}

.row:after, .row:before {
	box-sizing: border-box;
}

.row-list {
	display: flex;
	align-items: stretch;
}

[class*="col-"] {
	padding: 10px;
}

.form-group {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: top;
}

.form-group input {
	border-radius: 5px;
	border: 1px solid #000;
	width: 100%;
	padding: 5px;
	margin-top: 10px;
}

.form-group select {
	border-radius: 5px;
	border: 1px solid #000;
	width: 100%;
	padding: 5px;
	margin-top: 10px;
}

.form-group textarea {
	border-radius: 5px;
	border: 1px solid #000;
	width: 100%;
	padding: 5px;
	margin-top: 10px;
}

.photo-preview {
  background-color: var(--green-dark);
  padding-top: 10px;
  text-align: center;
  padding-bottom: 10px;
  border-radius: 10px;
  color: #000;
}

.photo-preview img {
  max-width: 90%;
  width: 90%;
  border-radius: 10px;
  height: auto;
}

.photo-preview p {
	margin: 0;
}

.photo-screen {
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: flex-start;
	background: var(--success-color);
	padding: 10px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	padding-bottom: 20px;
}

.photo-screen strong {
	color: #000;
	margin-bottom: 10px;
}

.photo-screen span {
	color: #000;
	margin-top: 10px;
}

.photo-screen input {
	color: #000;
	margin-bottom: 10px;
}

/* Extra small devices <= 600px */
@media only screen and (max-width: 600px) {
	.sidebar {
		width: 0;
	}
	.wrapper {
		margin-left: 0;
	}
	.sidebar-expand .wrapper {
		margin-left: 0;
	}
	.nav-right .nav-item {
		display: none;
	}
	.nav-right .mode, .nav-right .avatar-wrapper {
		display: list-item;;
	}
	.navbar-search {
		display: none;
	}
	.dropdown-menu {
		max-width: 300px;
	}
	[class*="col-xs"] {
		width: 100%
	}

	.col-xs-3 {width: 24%;}
	.col-xs-6 {width: 49%;}

	.button-bar {
		display: flex;
		flex-direction: column;
	}

	.button-bar-inspection {
		display: flex;
		flex-direction: row;
	}

	.photo-report-item {
		flex-direction: column;
	}
}

/* Small devices >= 600px */
@media only screen and (min-width: 600px) {
	.col-sm-1 {width: 8.33%;}
	.col-sm-2 {width: 16.66%;}
	.col-sm-3 {width: 24%;}
	.col-sm-4 {width: 33.33%;}
	.col-sm-5 {width: 41.66%;}
	.col-sm-6 {width: 49%;}
	.col-sm-7 {width: 58.33%;}
	.col-sm-8 {width: 66.66%;}
	.col-sm-9 {width: 75%;}
	.col-sm-10 {width: 83.33%;}
	.col-sm-11 {width: 91.66%;}
	.col-sm-12 {width: 100%;}

	.button-bar {
		display: flex;
		flex-direction: column;
	}

	.button-bar-inspection {
		display: flex;
		flex-direction: row;
	}

	.photo-report-item {
		flex-direction: column;
	}
}

/* Medium devices >= 768px */
@media only screen and (min-width: 768px) {
	.col-md-1 {width: 8.33%;}
	.col-md-2 {width: 16.66%;}
	.col-md-3 {width: 25%;}
	.col-md-4 {width: 33.33%;}
	.col-md-5 {width: 41.66%;}
	.col-md-6 {width: 50%;}
	.col-md-7 {width: 58.33%;}
	.col-md-8 {width: 66.66%;}
	.col-md-9 {width: 75%;}
	.col-md-10 {width: 83.33%;}
	.col-md-11 {width: 91.66%;}
	.col-md-12 {width: 100%;}

	.button-bar {
		display: flex;
		flex-direction: row;
	}

	.photo-report-item {
		flex-direction: row;
		vertical-align: top;
	}
}

/* laptops/desktops >= 992px */
@media only screen and (min-width: 992px) {
	.col-1 {width: 8.33%;}
	.col-2 {width: 16.66%;}
	.col-3 {width: 25%;}
	.col-4 {width: 33.33%;}
	.col-5 {width: 41.66%;}
	.col-6 {width: 50%;}
	.col-7 {width: 58.33%;}
	.col-8 {width: 66.66%;}
	.col-9 {width: 75%;}
	.col-10 {width: 83.33%;}
	.col-11 {width: 91.66%;}
	.col-12 {width: 100%;}
}

/* large laptops and desktops >= 1200px */
@media only screen and (min-width: 1200px) {
	.col-xl-1 {width: 8.33%;}
	.col-xl-2 {width: 16.66%;}
	.col-xl-3 {width: 25%;}
	.col-xl-4 {width: 33.33%;}
	.col-xl-5 {width: 41.66%;}
	.col-xl-6 {width: 50%;}
	.col-xl-7 {width: 58.33%;}
	.col-xl-8 {width: 66.66%;}
	.col-xl-9 {width: 75%;}
	.col-xl-10 {width: 83.33%;}
	.col-xl-11 {width: 91.66%;}
	.col-xl-12 {width: 100%;}
}

.counter {
	padding: 16px;
	text-align: center;
	color: #fff;
	border-radius: 10px;
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.counter svg {
	font-size: 50px;
}

.card {
	background: var(--main-bg-color);
	color: var(--main-color);
	border-radius: 10px;
	margin-bottom: 10px;
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.card-header {
	padding: 10px 20px;
	border-bottom: 1px solid var(--second-bg-color);
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.card-header svg {
	text-align: right;
}

.card-header svg:hover {
	cursor: pointer;
}

.card-header h3 {
	margin: 0;
}

.card-content {
	padding: 20px;
	overflow-x: auto;
}

table {
	border-collapse: collapse;
	width: 100%;
}

table td {
	border-top: 1px solid var(--second-bg-color);
	padding: 15px;
	vertical-align: top;
}

table td a {
	text-decoration: none;
	color: var(--main-color);
}

table tbody tr:hover {
	background-color: var(--second-bg-color);
}

table th {
	border-bottom: 1px solid var(--second-bg-color);
	padding: 15px 12px;
	text-align: left;
}

.dot i {
	display: inline-block;
	width: .385rem;
	height: .385rem;
	margin-right: .385rem;
	vertical-align: middle;
	border-radius: 50%;
}

.progress-wrapper {
	margin-bottom: 15px;
}

.float-right {
	float: right;
}

.progress {
	height: 10px;
	overflow: hidden;
	display: flex;
	font-size: .75rem;
	background: rgba(0,0,0,0.05);
	border-radius: .25rem;
	box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
}

.module-icon {
	font-size: 24px;
}

#version-number-container {
	margin-left: 10px;
}

#version-number {
	font-size: .8rem;
	font-style: italic;
}

.text-green {
	color: var(--success-color)!important
}

.text-red {
	color: var(--danger-color)
}

.pull-right {
	float: right;
}

.pull-left {
	float: left;
}

.title-bar {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	justify-content: space-between;
}

.title-bar .title {
	justify-content: flex-start;
}

.title-bar .icons {
	justify-content: flex-end;
	text-align: right;
}

.title-bar .icons a svg:hover {
	color: var(--icon-active-color);
}

.ui-icon {
	display: inline-block;
	font-size: 3rem;
	padding-top: .5rem;
	margin: 5px;
	color: var(--icon-color);
}

.ui-icon:hover {
	cursor: pointer;
	color: var(--icon-active-color);
}

.ui-icon.active {
	color: var(--icon-active-color);
}

.project-block {
	padding: 16px;
	text-align: center;
	color: #fff;
	border-radius: 10px;
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
	transition: 1s ease-in-out;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.project-block .project-logo-container {
	text-align: center;
	background: #fff;
	border-radius: 10px;
	padding: 16px;
	padding-bottom: 8px;
	margin-bottom: 8px;
	justify-items: center;
	align-items: stretch;
	height: 100px;
	width: 100%;
}

.project-block .project-logo-container .project-logo {
	width: auto;
	height: auto;
	max-height: 80px;
	max-width: 100%;
}

.project-block p {
	height: 24px;
}

a{
	text-decoration: none;
}

.nl2br {
	white-space: pre-wrap;
}

.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
	margin-top: 5px;
	margin-bottom: 5px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn-default {
	background: var(--info);
	color: var(--gray-dark);
	border-color: var(--gray);
}

.btn-primary {
	background: var(--blue);
	color: #fff;
	border-color: var(--blue);
}

.btn-secondary {
    color: #fff;
    background-color: var(--gray);
    border-color: var(--gray);
}

.btn-success {
    color: #fff;
    background-color: var(--green);
    border-color: var(--green);
}

.btn-danger {
    color: #fff;
    background-color: var(--red);
    border-color: var(--red);
}

.btn-warning {
    color: #212529;
    background-color: var(--yellow);
    border-color: var(--yellow);
}

.btn-info {
    color: #fff;
    background-color: var(--cyan);
    border-color: var(--cyan);
}

.button-bar a {
	color: #fff;
	white-space: nowrap; 
	overflow: hidden; 
	text-overflow: ellipsis;
}

.button-bar-inspection.pull-flex-right {
	flex-direction: row-reverse;
}

textarea {
	border-radius: 5px;
}

@keyframes next-animation {
	0% { opacity: 1; }
	50% { opacity: 0; transform: translateX(0); }
	51% { transform: translateX(-100%); }
	100% { transform: translateX(0); }
}

@keyframes previous-animation-disappear {
	0% { opacity: 1; transform: translateX(0); }
	100% { opacity: 1; transform: translateX(-100%); }
}

@keyframes previous-animation-fadein {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

.run-next-animation {
	animation: 1s next-animation;
}

.run-previous-animation {
	animation: previous-animation-disappear .5s, previous-animation-fadein 1s .5s;
}

.disabled {
    pointer-events: none; 
    opacity: 0.6;
}

.dropdown-menu-selection {
	list-style: none;
	display: none;
	flex-direction: column;
	position: absolute;
	right: 0px;
	top: 20px;
	background: var(--blue);
	color: #fff;
	max-width: 80vw;
	width: 80vw;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	z-index: 1;
	padding: unset;
	border-radius: 5px;
	border: 0.125px solid var(--second-bg-color);
	max-height: 60vh;
	overflow-y: scroll;
	word-spacing: words;
}

.dropdown-expand {
	display: flex;
	transition: 1s step-end;
}

.selection-item {
	padding: 10px;
}

.selection-item:hover {
	background: var(--primary-color);
}

.arrow {
	border: solid var(--blue);
	border-width: 0 4px 4px 0;
	display: block;
	padding: 5px;
}

.down {
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
}

.dropdown-icon {
	height: 25px;
	width: 25px;
	padding: 4px;
	padding-top: 0px;
	padding-left: 6px;
	padding-right: 13px;
	background-color: #fff;
	border-radius: 50%;
	margin: 0;
	border: 0px;
}

</style>
