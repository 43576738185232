<template>
    <div class="container">
        <div class="login-box">
            <div class="login-header">
                <img :src="logo" title="SMS Logo" />
                <h1>Welkom bij SMS App</h1>
            </div>

            <div class="login-body">
                <div class="form-group" id="user-group">
                    <label for="username">E-mailadres</label>
                    <input type="text" id="username" name="username" v-model="username" />
                </div>

                <div class="form-group" id="password-group">
                    <label for="password">Wachtwoord</label>
                    <input type="password" id="password" name="password" v-model="password" v-on:keyup.enter="onEnter" />
                </div>

                <button class="login-button" @click="login" id="button-group">
                    <font-awesome-icon :icon="spinner" id="login-spinner" class="fa-spin" v-if="isLoggingIn" />
                    Inloggen
                </button>
            </div>

            <div class="login-footer">
                <span class="forgot-password" id="forgot-password-group">Wachtwoord vergeten?</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

export default {
    data () {
        return {
            logo: require("../assets/logo.png"),
            username: '',
            password: ''
        }
    },
    watch: {
        loggedIn: function(val) {
            if (val) {
                setTimeout(() => {
                    window.location.href = '/';
                }, 500)
            }
        }
    },
    computed: {
        ...mapGetters('authentication', [
            'isLoggedIn',
            'isLoggingIn'
        ]),
        spinner() {
            return faSpinner
        },
        loggedIn() {
            return this.$store.state.authentication.loggedIn;
        }
    },
    methods: {
        login() {
            if (this.username.length > 0 && this.password.length > 0) {
                this.$store.dispatch('authentication/login', { 
                    username: this.username,
                    password: this.password 
                });
            }
        },
        onEnter() {
            this.login()
        }
    }
};
</script>

<style scoped>
:root {
    --success-color: #6ab04c;
    --success-second-color: #80d15d;
}

.container {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: contain;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
    background: url("~@/assets/background.jpg")
}

.login-box {
    width: 90vw;
    max-width: 350px;
    background: #efefef;
    box-shadow: 0 8px 12px 0 rgba(0,0,0,0.5);
    height: auto;
    padding: 2rem;
    border-radius: 5px;
    opacity: .95;
    transition: 1s ease-in-out;
    overflow: hidden;
}

.login-header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-header h1 {
    font-size: 1.4rem;
    font-weight: 400;
    padding-bottom: 10px;
    color: #000;
    margin-left: -600px;
    animation: intro .5s;
    animation-delay: .9s;
    animation-fill-mode: forwards;
}

@keyframes intro {
    0% { margin-left: -500px; }
    100% { margin-left: 0px; }
}

.login-header img {
    width: 40%;
    height: auto;
    animation: logo 1s;
}

@keyframes logo {
    0% { width: 0% }
    80% { width: 45%; }
    100% { width: 40%; }
}

.login-body {
    display: flex;
    flex-direction: column;
}

.form-group {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-bottom: 10px;
    opacity: 0;
    animation: appear 1s;
    animation-fill-mode: forwards;
}

@keyframes appear {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

#user-group {
    animation-delay: 1.2s;
}

#password-group {
    animation-delay: 1.4s;
}

#button-group {
    animation-delay: 1.6s;
}

#forgot-password-group {
    animation-delay: 1.8s;
}

.form-group input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #000;
}

.form-group label {
    color: #000;
    font-weight: 700;
    padding-bottom: 5px;
}

.login-button {
    padding: 10px;
    background-color: var(--success-color);
    border-radius: 5px;
    border-color: var(--success-color);
    color: #fff;
    margin-bottom: 20px;
    margin-top: 10px;
    opacity: 0;
    animation: appear 1s;
    animation-fill-mode: forwards;
}

.login-button:hover {
    background-color: #80d15d;
    cursor: pointer;
    transition: .5s background-color;
}

.login-footer {
    text-align: center;
}

.forgot-password {
    color: #000;
    opacity: 0;
    animation: appear 1s;
    animation-fill-mode: forwards;
}

.forgot-password a {
    text-decoration: none;
    color: #000;
}
</style>