import { handleResponse } from '@/helpers/response';
import { apiEndpoint } from '@/config/config'
import { localForageService } from "@/store/localforage";
import axios from 'axios'

const NAMESPACE = "USER";

export const user = {
    namespaced: true,
    state: () => ({
        me: {
            firstname: '',
            lastname: '',
            id: '',
            profile: {
                audit: false,
                afwijking: false,
                risk: false,
                se: false,
                vg: false,
                accident: false,
                raakvlak: false,
                people: false,
                rating: false,
                punchlist: false,
                visit_report: false,
                avatar: null
            }
        }
    }),
    actions: {
        getMe ({ dispatch, commit }) {

            const config = {
                method: 'GET',
                url: `${apiEndpoint}/api/v2/user/me`,
            };
        
            localForageService.startsWith(`${NAMESPACE}-ME`).then((me) => {
                if (typeof me[`${NAMESPACE}-ME`] !== 'undefined') {
                    commit('SUCCESS', { result: me[`${NAMESPACE}-ME`].result })
                }
            })

            axios(config).then(handleResponse)
            .then(
                response => {
                    commit('SUCCESS', response)

                    localForageService.setItem(`${NAMESPACE}-ME`, response)
                },
                error => {
                    dispatch("notifications/error", error, { root: true })
                }
            )
        }
    },
    mutations: {
        SUCCESS (state, response) {
            state.me = response.result;
        }
    },
    getters: {
        getMe (state) {
            return state.me;
        },
        getAvatar (state) {
            return state.me.profile.avatar;
        },
        getFullName (state) {
            const firstname = state.me.firstname;
            const lastname = state.me.lastname;

            return `${firstname} ${lastname}`;
        },
        getFirstName (state) {
            return state.me.firstname;
        },
        hasVG (state) {
            return state.me.profile.vg;
        },
        hasSE (state) {
            return state.me.profile.se;
        },
        hasAccident (state) {
            return state.me.profile.accident;
        },
        hasPunchList (state) {
            return state.me.profile.punchlist;
        },
        hasVisitReport (state) {
            return state.me.profile.visit_report;
        }
    }
}
