<template>
	<div>
        <div class="title-bar">
            <div class="icons">
                <router-link :to="{ name: 'VGProjectList', params: { }}">
                    <font-awesome-icon 
                        :icon="getBackIcon" 
                        class="ui-icon" 
                        ></font-awesome-icon>
                </router-link>
            </div>
            <div class="title">
                <h1><font-awesome-icon :icon="getVGIcon"></font-awesome-icon> V&amp;G Rapporten</h1>
            </div>
            <div class="icons">
                <router-link :to="{ name: 'VGReportNew', params: {}}">
                    <font-awesome-icon 
                        :icon="getAddIcon" 
                        class="ui-icon text-green" 
                        ></font-awesome-icon> 
                </router-link>
            </div>
        </div>

        <ReportTable :projectTitle="getProjectTitle" :reports="getProjectReports" :unsyncedReports="getProjectUnsyncedReports" />
	</div>
</template>

<script>
import { faFireExtinguisher, faWheelchair, faSitemap, faListAlt, faPlusCircle, faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { mapGetters } from 'vuex';
import ReportTable from '@/components/elements/report-table'

export default {
    name: "ReportList",
    components: {
        ReportTable
    },
    props: {
        module: {
            optional: false
        }
    },
    computed: {
		...mapGetters('vg', [
            'getVGReports',
            'getVGProjects',
            'getVGUnsyncedReports'
		]),
        getProjectReports() {
            const pid = this.$route.params.project_id;
            return this.getVGReports[pid];
        },
        getProjectUnsyncedReports() {
            const pid = this.$route.params.project_id;
            return this.getVGUnsyncedReports[pid];
        },
        getProjectTitle() {
            const pid = this.$route.params.project_id
            const project = this.getVGProjects.filter((project) => project.id === pid)

            if (typeof project[0] === 'undefined') {
                return ''
            }

            return project[0].title
        },
        getVGIcon() {
            return faFireExtinguisher
        },
        getAccidentIcon() {
            return faWheelchair
        },
        getSEIcon() {
            return faSitemap
        },
        getPunchListIcon() {
            return faListAlt
        },
        getAddIcon() {
            return faPlusCircle
        },
        getBackIcon() {
            return faChevronCircleLeft
        }
    }
}
</script>