<template>
	<div>
        <div class="title-bar">
            <div class="icons">
                <router-link :to="{ name: 'HomePage', params: { }}">
                    <font-awesome-icon 
                        :icon="getBackIcon" 
                        class="ui-icon" 
                        ></font-awesome-icon>
                </router-link>
            </div>
            <div class="title">
                <h1><font-awesome-icon :icon="getVGIcon"></font-awesome-icon> V&amp;G Projecten</h1>
            </div>
            <div class="icons">
                <font-awesome-icon 
                    :icon="getBlockIcon" 
                    class="ui-icon" 
                    v-bind:class="{ active: showBlocks }"
                    v-on:click="switchToBlocks"></font-awesome-icon>

                <font-awesome-icon 
                    :icon="getTableIcon" 
                    class="ui-icon" 
                    v-bind:class="{ active: showTable }"
                    v-on:click="switchToTable"></font-awesome-icon>
            </div>
        </div>

        <ProjectBlocks :show="showBlocks" :projects="getVGProjects" />
        <ProjectTable :show="showTable" :projects="getVGProjects" />
	</div>
</template>

<script>
import { faFireExtinguisher, faThLarge, faThList, faWheelchair, faSitemap, faListAlt, faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { mapGetters } from 'vuex';
import { version } from '@/config/version'
import ProjectTable from '@/components/elements/project-table'
import ProjectBlocks from '@/components/elements/project-blocks'
import { localForageService } from "@/store/localforage";

export default {
    name: "ProjectList",
    components: {
        ProjectTable,
        ProjectBlocks
    },
    props: {
        module: {
            optional: false
        }
    },
	created() {
        this.loadPreference();
    },
    data() {
        return {
            showBlocks: false,
            showTable: true
        }
    },
    methods: {
        switchToBlocks() {
            this.showBlocks = true;
            this.showTable = false;

            localForageService.getItem('PREFERENCES').then((preference) => {
                if (preference === null) {
                    preference = {
                        theme: 'light',
                        blocksTable: 'blocks'
                    }
                } else {
                    preference.blocksTable = 'blocks'
                }

                localForageService.setItem('PREFERENCES', preference);
            });
        },
        switchToTable() {
            this.showBlocks = false;
            this.showTable = true

            localForageService.getItem('PREFERENCES').then((preference) => {
                if (preference === null) {
                    preference = {
                        theme: 'light',
                        blocksTable: 'table'
                    }
                } else {
                    preference.blocksTable = 'table'
                }

                localForageService.setItem('PREFERENCES', preference);
            });
        },
        loadPreference() {
            localForageService.getItem('PREFERENCES').then((preference) => {
                if (preference !== null && typeof preference.blocksTable !== 'undefined') {
                    if (preference.blocksTable === 'blocks') {
                        this.switchToBlocks()
                    } else {
                        this.switchToTable()
                    }
                }
                else {
                    this.switchToTable()
                }
            })
        }
    },
    computed: {
		...mapGetters('vg', [
			'getVGProjects'
		]),
		...mapGetters('user', [
			'getFirstName',
			'getFullName',
			'hasVG',
			'hasSE',
			'hasAccident',
			'hasPunchList',
			'hasVisitReport'
        ]),
		getVersion() {
			return version
		},
		getTimeOfDay() {
			const now = new Date();
			if (now.getHours() >= 0 && now.getHours() < 6) { return "Goedenacht"; }
			if (now.getHours() >= 6 && now.getHours() < 12) { return "Goedemorgen"; }
			if (now.getHours() >= 12 && now.getHours() < 18) { return "Goedemiddag"; }

			return "Goedenavond";
		},
        getVGIcon() {
            return faFireExtinguisher
        },
        getAccidentIcon() {
            return faWheelchair
        },
        getSEIcon() {
            return faSitemap
        },
        getPunchListIcon() {
            return faListAlt
        },
        getBlockIcon() {
            return faThLarge
        },
        getTableIcon() {
            return faThList
        },
        getBackIcon() {
            return faChevronCircleLeft
        }
    }
}
</script>