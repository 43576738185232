<template>
    <div>
        <div class="row" v-if="show">
			<div class="col-12 col-md-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-header bg-primary">
						<h3>
							Projecten
						</h3>
					</div>
					<div class="card-content">
						<table>
							<thead>
								<tr>
									<th>Project</th>
									<th>Locatie</th>
								</tr>
							</thead>
							<tbody>
								<template v-for="project in projects" >
								<tr v-bind:key="project.id">
									<td>
                                        <router-link :to="{ name: 'VGReportList', params: { project_id: project.id }}">
                                            {{ project.title }}
                                        </router-link>
                                    </td>
									<td>{{ project.location }}</td>
								</tr>
								</template>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>

<script>
export default {
    name: "ProjectTable",
    props: {
        show: {
            optional: false
        },
        projects: {
            optional: false
        }
    }
}
</script>