<template>
    <div>
        <div class="row">
			<div class="col-12 col-md-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-header bg-primary">
						<h3>
							Rapporten van {{ projectTitle }}
						</h3>
					</div>
					<div class="card-content">
						<table>
							<thead>
								<tr>
									<th>#</th>
									<th>Rapport Datum</th>
                                    <th>Locatie</th>
                                    <th>(Hoofd)uitvoerder</th>
                                    <th>Status</th>
								</tr>
							</thead>
							<tbody>
                                <template v-for="report in unsyncedReports" >
                                    <tr v-bind:key="report.id">
                                        <td>
                                            <router-link :to="{ name: 'VGReportDetailsNew', params: { project_id: pid, report_id: report.id }}">
                                                {{ report.prefix }}
                                            </router-link>
                                        </td>
                                        <td>{{ report.report_date }}</td>
                                        <td>{{ report.location }}</td>
                                        <td v-html="report.chief_engineer"></td>
                                        <td>{{ convertReportStatus(report.status) }}</td>
                                    </tr>
                                </template>
                                <template v-for="report in reports" >
                                    <tr v-bind:key="report.id">
                                        <td>
                                            <router-link :to="{ name: 'VGReportDetails', params: { project_id: pid, report_id: report.id }}">
                                                {{ report.prefix }}{{ report.serial_number }}
                                            </router-link>
                                        </td>
                                        <td>{{ report.report_date }}</td>
                                        <td>{{ report.location }}</td>
                                        <td v-html="report.chief_engineer"></td>
                                        <td>{{ convertReportStatus(report.status) }}</td>
                                    </tr>
                                </template>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>

<script>
export default {
    name: "ReportTable",
    props: {
        projectTitle: {
            optional: false
        },
        reports: {
            optional: false
        },
        unsyncedReports: {
            optional: false
        }
    },
    data() {
        return {
            pid: this.$route.params.project_id
        }
    },
    methods: {
        convertReportStatus(status) {
            switch (status) {
                case 0: return "Concept";
                case 1: return "Gepubliceerd";
                case 2: return "Vervallen";
                default:
                    return "Onbekend";
            }
        }
    }
}
</script>