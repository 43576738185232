import { localForageService } from "@/store/localforage";

export const tokenStorage = {
    setToken,
    getAccessToken,
    getRefreshToken,
    clearToken,
    logOut,
    redirectFromLogin
};

function setToken(tokenObj) {
    localForageService.getItem('USER_DATA').then(() => {
        const now = new Date()
        let expiresIn = new Date(now.getTime() + tokenObj.expires_in * 1000)

        let data = {
            access_token: tokenObj.access_token,
            refresh_token: tokenObj.refresh_token,
            valid_until: parseInt((expiresIn.getTime() / 1000).toFixed(0))
        }

        localForageService.setItem('USER_DATA', data)
    })
}

async function getAccessToken() {
    const data = await localForageService.getItem('USER_DATA');

    if (data === null) {
        return null;
    }

    return data.access_token;
}

async function getRefreshToken() {
    const data = await localForageService.getItem('USER_DATA')

    if (data === null) {
        return null;
    }

    return data.refresh_token;
}

function clearToken() {
    localForageService.removeItem('USER_DATA');
}

async function logOut() {
    const reports = await localForageService.startsWith('VG-REPORT').then((reports) => {
       return reports
    })

    Object.keys(reports).map((key) => {
        localForageService.removeItem(key)
    })

    const projects = await localForageService.startsWith('VG-PROJECT').then((projects) => {
        return projects
    })

    Object.keys(projects).map((key) => {
        localForageService.removeItem(key)
    })

    clearToken();
    window.location.href = '/login';
}

function redirectFromLogin() {
    window.location.href = '/';
}