<template>
    <!-- sidebar -->
	<div class="sidebar">
		<ul class="sidebar-nav">
			<li class="sidebar-nav-item">
				<router-link :to="{ name: 'HomePage', params: { }}" class="sidebar-nav-link">
				<!-- <a href="#" class="sidebar-nav-link"> -->
					<div>
						<font-awesome-icon :icon="getHomeIcon" />
					</div>
					<span>
						Home
					</span>
				<!-- </a> -->
				</router-link>
			</li>
			<li class="sidebar-nav-item" v-if="hasVG">
				<router-link :to="{ name: 'VGProjectList', params: { }}" class="sidebar-nav-link">
				<!-- <a href="#" class="sidebar-nav-link"> -->
					<div>
						<font-awesome-icon :icon="getVGIcon" />
					</div>
					<span>V&amp;G</span>
				<!-- </a> -->
				</router-link>
			</li>
			<li  class="sidebar-nav-item" v-if="hasAccident">
				<a href="#" class="sidebar-nav-link">
					<div>
						<font-awesome-icon :icon="getAccidentIcon" />
					</div>
					<span>(Bijna)ongevallen</span>
				</a>
			</li>
			<li  class="sidebar-nav-item" v-if="hasPunchList">
				<a href="#" class="sidebar-nav-link">
					<div>
						<font-awesome-icon :icon="getPunchListIcon" />
					</div>
					<span>Restpunten</span>
				</a>
			</li>
			<li  class="sidebar-nav-item">
				<a href="#" class="sidebar-nav-link" v-if="hasSE">
					<div>
						<font-awesome-icon :icon="getSEIcon" />
					</div>
					<span>Keuringen</span>
				</a>
			</li>
			<!-- <li  class="sidebar-nav-item" v-if="hasVisitReport">
				<a href="#" class="sidebar-nav-link">
					<div>
						<font-awesome-icon :icon="getVisitReportIcon" />
					</div>
					<span>Visite Rapporten</span>
				</a>
			</li> -->
		</ul>
	</div>
	<!-- end sidebar -->
</template>

<script>
import { faTachometerAlt, faFireExtinguisher, faWheelchair, faSitemap, faListAlt } from '@fortawesome/free-solid-svg-icons'
import { mapGetters } from 'vuex'

export default {
    name: "Sidebar",
    computed: {
		...mapGetters('user', [
			'hasVG',
			'hasSE',
			'hasAccident',
			'hasPunchList',
			'hasVisitReport'
		]),
		getHomeIcon() {
			return faTachometerAlt
		},
        getVGIcon() {
            return faFireExtinguisher
        },
        getAccidentIcon() {
            return faWheelchair
        },
        getSEIcon() {
            return faSitemap
        },
        getPunchListIcon() {
            return faListAlt
        },
    }
}
</script>