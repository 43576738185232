<template>
	<div>
        <div class="title-bar">
            <div class="icons">
                <router-link :to="{ name: 'VGReportList', params: { id: getPid }}">
                    <font-awesome-icon 
                        :icon="getBackIcon" 
                        class="ui-icon" 
                        ></font-awesome-icon>
                </router-link>
            </div>
            <div class="title">
                <h1><font-awesome-icon :icon="getVGIcon"></font-awesome-icon> {{ getSerialNumber }}</h1>
            </div>
        </div>

        <General :report="getReport" v-if="!isNewReport() && !isUnsyncedReport()" />
        <GeneralNew :report="getReport" v-if="isNewReport()" />
        <GeneralUnsynced :report="getReport" v-if="isUnsyncedReport()" />
	</div>
</template>

<script>
import { faFireExtinguisher, faWheelchair, faSitemap, faListAlt, faPlusCircle, faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { mapGetters } from 'vuex';
import General from '@/components/report/vg/general'
import GeneralNew from '@/components/report/vg/new/general'
import GeneralUnsynced from '@/components/report/vg/unsynced/general'

export default {
    name: "ReportList",
    components: {
        General,
        GeneralNew,
        GeneralUnsynced
    },
    props: {
        module: {
            optional: false
        }
    },
    methods: {
        isNewReport() {
            return (this.$route.name === "VGReportNew")
        },
        isUnsyncedReport() {
            return (this.$route.name === "VGReportDetailsNew")
        },
        isRegularReport() {
            return (this.$route.name === "VGReportDetails")
        }
    },
    computed: {
        ...mapGetters('vg', [
            'getVGReports',
            'getVGProjects',
            'getVGUnsyncedReports'
        ]),
        getPid() {
            return this.$route.params.project_id;
        },
        getProject() {
            const pid = this.$route.params.project_id;
            const project = this.getVGProjects.filter((project) => project.id === pid);

            return project[0];
        },
        getProjectTitle() {
            const project = this.getProject()
            return project.title
        },
        getSerialNumber() {
            if (!this.isNewReport() && !this.isUnsyncedReport()) {
                const report = this.getReport;

                return `${report.prefix}${report.serial_number}`;
            } else {
                if (this.isUnsyncedReport()) {
                    return "Te syncen rapport"
                }

                return 'Nieuw rapport';
            }
        },
        getReport() {
            if (!this.isNewReport()) {
                const pid = this.$route.params.project_id;
                const rid = this.$route.params.report_id;

                if (this.isUnsyncedReport()) {
                    const projectReports = this.getVGUnsyncedReports[pid];
                    const report = projectReports.filter((report) => report.id === rid)[0];

                    return report;
                } else {
                    const projectReports = this.getVGReports[pid];
                    const report = projectReports.filter((report) => report.id === rid)[0];

                    return report;
                }
            } else {
                // We need a clone of empty_report. Otherwise we're going to change it here too.
                const emptyReport = JSON.parse(JSON.stringify(this.getProject.empty_report));

                return emptyReport;
            }
        },
        getVGIcon() {
            return faFireExtinguisher
        },
        getAccidentIcon() {
            return faWheelchair
        },
        getSEIcon() {
            return faSitemap
        },
        getPunchListIcon() {
            return faListAlt
        },
        getAddIcon() {
            return faPlusCircle
        },
        getBackIcon() {
            return faChevronCircleLeft
        }
    }
}
</script>