<template>
    <!-- main content -->
	<div class="wrapper">

		<Home v-if="isHome" />
		<ProjectList v-if="isProjectList" :module="getModule" />
		<ReportList v-if="isReportList" :module="getModule" />
		<ReportGeneral v-if="isReportDetails" :module="getModule" />
		<ReportInspection v-if="isReportInspection" :module="getModule" />
		<ReportPhoto v-if="isReportPhoto" :module="getModule" />
		<ReportSigning v-if="isReportSigning" :module="getModule" />

		<UploadPage v-if="isUpload" />
		<DownloadPage v-if="isDownload" />

		<div id="version-number-container">
			<span id="version-number">Versie: {{ getVersion }}</span>
		</div>
	</div>
	<!-- end main content -->
</template>

<script>
import { faFireExtinguisher, faWheelchair, faSitemap, faListAlt } from '@fortawesome/free-solid-svg-icons'
// import { faStackExchange } from '@fortawesome/free-brands-svg-icons'
import { version } from '@/config/version'
import Home from './home'
import ProjectList from '@/components/project/project-list'
import ReportList from '@/components/report/report-list'
import ReportGeneral from '@/components/report/report-general'
import ReportInspection from '@/components/report/report-inspection'
import ReportPhoto from '@/components/report/report-photo'
import ReportSigning from '@/components/report/report-signing'
import UploadPage from '@/components/network/upload'

export default {
	name: "Main",
	components: {
		Home,
		ProjectList,
		ReportList,
		ReportGeneral,
		ReportInspection,
		ReportPhoto,
		ReportSigning,
		UploadPage
	},
    computed: {
		isHome() {
			return this.$route.name === "HomePage"
		},
		isProjectList() {
			return this.$route.name === "VGProjectList"
		},
		isReportList() {
			return this.$route.name === "VGReportList"
		},
		isReportDetails() {
			return (this.$route.name === "VGReportDetails" || 
					this.$route.name === "VGReportNew" || 
					this.$route.name === "VGReportDetailsNew")
		},
		isReportInspection() {
			return (this.$route.name === "VGReportInspection" ||
					this.$route.name === "VGReportNewInspection")
		},
		isReportPhoto() {
			return (this.$route.name === "VGReportPhoto" ||
					this.$route.name === "VGReportNewPhoto")
		},
		isReportSigning() {
			return (this.$route.name === "VGReportSigning" ||
					this.$route.name === "VGReportNewSigning")
		},
		isUpload() {
			return (this.$route.name === "UploadPage")
		},
		isDownload() {
			return (this.$route.name === "DownloadPage")
		},
		getModule() {
			return "vg"
		},
		getVersion() {
			return version
		},
        getVGIcon() {
            return faFireExtinguisher
        },
        getAccidentIcon() {
            return faWheelchair
        },
        getSEIcon() {
            return faSitemap
        },
        getPunchListIcon() {
            return faListAlt
        }
    }
}
</script>