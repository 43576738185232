<template>
    <div>
        <Navbar />
        <Sidebar />
        <Main />
    </div>
</template>

<script>
import Navbar from '@/components/main-ui/navbar'
import Sidebar from '@/components/main-ui/sidebar'
import Main from '@/components/main-ui/main'

export default {
    name: "Page",
    components: {
        Navbar,
        Sidebar,
        Main
    },
    created() {
        this.$store.dispatch("user/getMe")
    },
    computed: {
        loggedIn() {
            return this.$store.state.authentication.loggedIn;
        }
    }
}
</script>