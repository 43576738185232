<template>
    <div>
        <div class="row">
			<div class="col-12 col-m-12 col-sm-12">
                <template v-if="report.photos.length === 0">
                    <center><em>Geen foto's bij dit rapport.</em></center>
                </template>
                <template v-for="photo in report.photos">
                    <div class="card" v-bind:key="photo.id">
                        <div class="card-content photo-report-item">
                            <div class="col-6 col-md-6 col-sm-12 col-xs-12">
                                <img :src="getPhoto(photo.id)" title="Foto" style="max-width: 100%; height: auto;" />
                            </div>

                            <div class="nl2br col-6 col-md-6 col-sm-12 col-xs-12">
                                {{ photo.description }}
                                <img :src="convertIndicator(photo.indicator.url)" :title="photo.indicator.title" style="display: block; max-width: 60px; width: 60px; height: auto; padding: 10px; float: right;" />
                            </div>
                        </div>
                    </div>
                </template>
			</div>
		</div>
    </div>
</template>

<script>
import { faImages, faSignature, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { mapGetters } from 'vuex';

export default {
    name: "General",
    data() {
        return {
            good: require("@/assets/indicators/good.png"),
            sad: require("@/assets/indicators/sad.png"),
            danger: require("@/assets/indicators/danger.png"),
            housekeeping: require("@/assets/indicators/housekeeping.png"),
            questionmark: require("@/assets/indicators/questionmark.png")
        }
    },
    props: {
        report: {
            optional: false
        }
    },
    computed: {
		...mapGetters('vg', [
            'getVGProjects',
            'getVGPhotos'
        ]),
        getPid() {
            return this.$route.params.project_id;
        },
        getInspectionIcon() {
            return faPencilAlt
        },
        getImageIcon() {
            return faImages
        },
        getSignatureIcon() {
            return faSignature
        }
    },
    methods: {
        getSerialNumber() {
            const project = this.getProject();

            return `${project.settings.prefix}${this.report.serial_number}`
        },
        getProject() {
            const pid = this.$route.params.project_id;
            const project = this.getVGProjects.filter((project) => project.id === pid);

            return project[0];
        },
        convertIndicator(indicator) {
            switch (indicator) {
                case "good": return this.good;
                case "danger": return this.danger;
                case "housekeeping": return this.housekeeping;
                case "questionmark": return this.questionmark;
                case "sad": return this.sad;
                default:
                    return "";
            }
        },
        getPhoto(photoId) {
            const rid = this.$route.params.report_id;
            if (this.getVGPhotos === null) {
                return;
            }

            if (typeof this.getVGPhotos[rid] === 'undefined') {
                return;
            }

            let photo = this.getVGPhotos[rid].filter((photo) => photo.id === photoId);
            return photo[0].photo
        },
    }
}
</script>