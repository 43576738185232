<template>
    <!-- navbar -->
	<div class="navbar">
		<!-- nav left -->
		<ul class="navbar-nav">
			<li class="nav-item" style="margin-left: 10px;">
				<img :src="logoBlack" alt="SMS logo" class="logo logo-light">
				<img :src="logoWhite" alt="SMS logo" class="logo logo-dark">
			</li>
			<li class="nav-item">
				<a class="nav-link">
                    <font-awesome-icon :icon="bars" @click="collapseSidebar" />
				</a>
			</li>
		</ul>
		<!-- end nav left -->
		<!-- nav right -->
		<ul class="navbar-nav nav-right">
            <li class="nav-item mode">
                <div class="nav-icon">
                    <font-awesome-icon :icon="internetIcon" title="Online" class="text-green" v-if="onLine" />
                    <font-awesome-icon :icon="internetIcon" title="Offline" class="text-red" v-if="!onLine" />
                </div>
            </li>
            <li class="nav-item mode">
                <a class="nav-link" href="#" @click="switchTheme">
                    <font-awesome-icon :icon="moon" class="dark-icon" />
                    <font-awesome-icon :icon="sun" class="light-icon" />
                </a>
            </li>
			<li class="nav-item avatar-wrapper">
				<div class="avatar dropdown">
					<img :src="getAvatar" alt="User image" class="dropdown-toggle" data-toggle="user-menu">
					<ul id="user-menu" class="dropdown-menu">
						<li  class="dropdown-menu-item">
							<a href="#" class="dropdown-menu-link" @click="signOff($event)">
								<div>
                                    <font-awesome-icon :icon="signOut" />
								</div>
								<span>Logout</span>
							</a>
						</li>
					</ul>
				</div>
			</li>
		</ul>
		<!-- end nav right -->
	</div>
	<!-- end navbar -->
</template>

<script>
const themeDark = 'dark'
const themeLight = 'light'
const body = document.getElementsByTagName('body')[0]

import { faWifi, faBars, faSearch, faBell, faMoon, faSun, faGift, faTasks, faUserTie, faCog, faSpinner, faCreditCard, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { mapGetters } from 'vuex';
import { localForageService } from "@/store/localforage";

export default {
    name: "Navbar",
    data () {
        return {
            onLine: navigator.onLine,
            logoBlack: require("@/assets/logo.png"),
            logoWhite: require("@/assets/logo-white.png"),
            avatar: require("@/assets/avatar.png")
        }
    },
    mounted() {
        window.addEventListener('online', this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);
    },
    beforeDestroy() {
        window.removeEventListener('online', this.updateOnlineStatus);
        window.removeEventListener('offline', this.updateOnlineStatus);
    },
    computed: {
        ...mapGetters('user', [
            'getAvatar'
        ]),
        bars() {
            return faBars
        },
        search() {
            return faSearch
        },
        bell() {
            return faBell
        },
        moon() {
            return faMoon
        },
        sun() {
            return faSun
        },
        gift() {
            return faGift
        },
        tasks() {
            return faTasks
        },
        userTie() {
            return faUserTie
        },
        cog() {
            return faCog
        },
        creditCard() {
            return faCreditCard
        },
        spinner() {
            return faSpinner
        },
        signOut() {
            return faSignOutAlt
        },
        internetIcon() {
            return faWifi
        }
    },
    methods: {
        addNotification() {
            this.$store.dispatch("notifications/error", "Test ding!")
        },
        switchTheme() {
            if (body.classList.contains(themeLight)) {
                body.classList.remove(themeLight)
                body.classList.add(themeDark)

                localForageService.getItem('PREFERENCES').then((preference) => {
                    if (preference === null) {
                        preference = {
                            theme: themeDark,
                            blocksTable: 'table'
                        }
                    } else {
                        preference.theme = themeDark
                    }

                    localForageService.setItem('PREFERENCES', preference)
                })
            } else {
                body.classList.remove(themeDark)
                body.classList.add(themeLight)

                localForageService.getItem('PREFERENCES').then((preference) => {
                    if (preference === null) {
                        preference = {
                            theme: themeLight,
                            blocksTable: 'table'
                        }
                    } else {
                        preference.theme = themeLight
                    }

                    localForageService.setItem('PREFERENCES', preference)
                })
            }
        },
        collapseSidebar() {
            body.classList.toggle('sidebar-expand')
        },
        signOff(event) {
            if (event) {
                event.preventDefault();
            }
            
            this.$store.dispatch('authentication/logout')
        },
        updateOnlineStatus(e) {
            const {
                type
            } = e;
            this.onLine = type === 'online';
        }
    }
}
</script>