import axios from 'axios';
import { apiEndpoint, clientId, clientSecret } from '@/config/config';
import { tokenStorage }  from '@/helpers/token-storage';
// import { store } from '@/store';

export default function setup() {
    axios.interceptors.response.use(
        response => {
          return response;
        },
        async function (error) {
			const originalRequest = error.config;
		
			if (error.response.status === 401 && originalRequest.url === `${apiEndpoint}/oauth/v2/token`) {
				tokenStorage.logOut();
				// console.log("Refresh Token also expired, logging out...")
				if (this.$route.history.current.name !== "login") {
					window.location.href = '/';
				}

				return Promise.reject(error);
			}
       
			if (error.response.status === 401 && !originalRequest._retry) {
		
				originalRequest._retry = true;
				const refreshToken = await tokenStorage.getRefreshToken();
				// console.log("Refreshing: ", refreshToken);
				const grantType = "refresh_token";

				return axios.post(`${apiEndpoint}/oauth/v2/token`, JSON.stringify({ 
						"grant_type": grantType, 
						"client_id": clientId, 
						"client_secret": clientSecret, 
						"refresh_token": refreshToken
					}))
					.then(res => {
						if (res.status === 200) {

							tokenStorage.setToken(res.data);
							axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access_token;
							
							return axios(originalRequest);
						}
					})
			}

			return Promise.reject(error);
		}
	);

	axios.interceptors.request.use(
		async config => {
			const token = await tokenStorage.getAccessToken();
			// console.log("Token: ", token);

			if (token) {
				config.headers['Authorization'] = 'Bearer ' + token;
			}
			
			config.headers['Content-Type'] = 'application/json';
			return config;
		},
		error => {
			Promise.reject(error)
		}
	);
}