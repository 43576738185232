export function handleResponse(response) {

    const data = response.data;
    if (response.status >= 400) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            this.$root.dispatch("authentication/logout")
        }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }

    if (typeof(response.data) === "undefined")
    {
        return response;
    }

    return response.data;
}